import React from 'react';

import image from '../../images/OrderStation.png';
import { StoreContext } from '../../store';

import style from './Order.module.css';

type Props = {
  number: number;
};

function Order({ number = 15000 }: Props): JSX.Element {
  const { contactUs } = React.useContext(StoreContext);
  return (
    <article className={style.container}>
      <h3 className={style.number}>{number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</h3>
      <p className={style.text}>скважин построено с нами</p>
      <button className={style.button} onClick={contactUs.open}>
        Заказать бесплатную демонстрацию
      </button>
      {/* <div className={style.shadow} /> */}
      <img className={style.image} src={image} title="Нефтяная вышка" alt="Нефтяная вышка на открытом пространстве" />
      <div className={style.square} />
    </article>
  );
}

export default Order;
