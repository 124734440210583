import React from 'react';
import { Formik, Field, Form } from 'formik';

import CloseButton from '../Buttons/CloseButton';
import Input from '../Inputs/Input';
import PasswordInput from '../Inputs/PasswordInput';
import SubmitButton from '../Buttons/SubmitButton';
import { StoreContext } from '../../store';

import Modal from './Modal';
import Schema from './SignInSchema';
import style from './SignIn.module.css';

type MyFormValues = {
  email: string;
  password: string;
};

function SignIn(): JSX.Element {
  const { signUp, signIn, forgot } = React.useContext(StoreContext);

  function handleSubmit(values: MyFormValues): void {
    signIn.close();
  }

  function renderForm(): JSX.Element {
    return (
      <Form className={style.form}>
        <Field type="text" name="email" component={Input} placeholder={'Email'} icon="email" />
        <Field type="password" name="password" component={PasswordInput} placeholder={'Пароль'} />
        <button type="button" className={style.openForgot} onClick={forgot.open}>
          Забыли пароль?
        </button>
        <SubmitButton title="Войти" className={style.button} />
        <p className={style.text}>
          Вы здесь впервые?
          <button type="button" className={style.openSignUp} onClick={signUp.open}>
            Зарегистрируйтесь
          </button>
        </p>
      </Form>
    );
  }

  return (
    <Modal signIn>
      <CloseButton close={signIn.close} />
      <h2 className={style.title}>Вход</h2>
      <p>Рады видеть Вас снова!</p>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={Schema}
        onSubmit={handleSubmit}
        render={renderForm}
      />
    </Modal>
  );
}

export default SignIn;
