import React from 'react';

type Props = {
  green?: boolean;
  white?: boolean;
};

function InputIconAddress({ green, white }: Props): JSX.Element {
  function defineColor(): string {
    if (green) return 'var(--green)';
    else if (white) return '#FFFFFF';
    else return '#E5E5E5';
  }

  return (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.9953 0C4.03659 0 0 3.95723 0 8.81845C0 9.37191 0.0517511 9.92998 0.159958 10.4742C0.164663 10.5065 0.183481 10.6034 0.221119 10.7694C0.357554 11.3644 0.559853 11.9501 0.823314 12.5082C1.79247 14.7451 3.92368 18.1811 8.60011 21.8616C8.71772 21.9539 8.85886 22 9 22C9.14114 22 9.28228 21.9539 9.3999 21.8616C14.0716 18.1811 16.2075 14.7451 17.1767 12.5082C17.4401 11.9501 17.6424 11.369 17.7789 10.7694C17.8165 10.6034 17.8353 10.5065 17.84 10.4742C17.9435 9.92998 18 9.37191 18 8.81845C17.9906 3.95723 13.954 0 8.9953 0ZM16.5792 10.2574C16.5792 10.2667 16.5745 10.2759 16.5745 10.2851C16.5698 10.3082 16.5557 10.3774 16.5322 10.4834C16.5322 10.4881 16.5322 10.4881 16.5322 10.4927C16.4145 11.0092 16.2405 11.512 16.0099 11.9962C16.0052 12.0008 16.0052 12.0101 16.0005 12.0147C15.1208 14.0579 13.1918 17.1757 8.9953 20.5748C4.79875 17.1757 2.86984 14.0579 1.99007 12.0147C1.98536 12.0101 1.98536 12.0008 1.98066 11.9962C1.75484 11.5166 1.58076 11.0092 1.45844 10.4927C1.45844 10.4881 1.45844 10.4881 1.45844 10.4834C1.43021 10.3774 1.42081 10.3082 1.4161 10.2851C1.4161 10.2759 1.4114 10.2667 1.4114 10.2528C1.3173 9.77778 1.27026 9.29811 1.27026 8.81384C1.27026 4.63983 4.73759 1.24067 8.9953 1.24067C13.253 1.24067 16.7203 4.63983 16.7203 8.81384C16.7203 9.30273 16.6733 9.787 16.5792 10.2574Z"
        fill={defineColor()}
      />
      <path
        d="M8.99856 3.31616C5.85114 3.31616 3.28711 5.82979 3.28711 8.91532C3.28711 12.0009 5.85114 14.5145 8.99856 14.5145C12.146 14.5145 14.71 12.0009 14.71 8.91532C14.71 5.82979 12.146 3.31616 8.99856 3.31616ZM8.99856 13.2692C6.54743 13.2692 4.55737 11.3136 4.55737 8.91532C4.55737 6.517 6.55214 4.56144 8.99856 4.56144C11.445 4.56144 13.4397 6.517 13.4397 8.91532C13.4397 11.3136 11.4497 13.2692 8.99856 13.2692Z"
        fill={defineColor()}
      />
    </svg>
  );
}

export default InputIconAddress;
