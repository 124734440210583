import React, { useState } from 'react';
import cn from 'classnames';
import useInterval from 'use-interval';

import { Review as ReviewType } from './types';
import ReviewItem from './ReviewItem';
import ReviewScroll from './ReviewScroll';
import ReviewMock from './ReviewMock.json';
import style from './Review.module.css';

function Review(): JSX.Element {
  const [currentReview, setCurrentReview] = useState(0);
  const { length } = ReviewMock;

  useInterval(() => {
    if (currentReview === length - 1) {
      setCurrentReview(0);
    } else {
      setCurrentReview(currentReview + 1);
    }
  }, 10000);

  return (
    <article className={style.container} id="review">
      <h3 className={style.name}>Отзывы</h3>
      {/* <ReviewItem review={ReviewMock[0]} className={cn(style.item, style.first_mobile)} /> */}
      {/* <ReviewItem review={ReviewMock[1]} className={cn(style.item, style.second_mobile)} /> */}
      {ReviewMock.map((item: ReviewType, index: number) => (
        <ReviewItem
          key={item.id}
          review={item}
          className={cn(
            style.item,
            index === currentReview && style.first,
            index === (currentReview + 1) % length && style.second,
            index === (currentReview + 2) % length && style.right,
          )}
        />
      ))}
      <ReviewScroll current={currentReview} reviews={ReviewMock} />
    </article>
  );
}

export default Review;
