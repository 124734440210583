import React from 'react';

type Props = {
  green?: boolean;
  white?: boolean;
};

function InputIconEye({ green, white }: Props): JSX.Element {
  function defineColor(): string {
    if (green) return 'var(--green)';
    else if (white) return '#FFFFFF';
    else return '#E5E5E5';
  }

  return (
    <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.7546 7.16161C20.6086 5.30626 19.0677 3.81549 17.1322 2.68934C15.1965 1.56316 13.1525 1 11 1C8.84746 1 6.8034 1.56294 4.86775 2.68934C2.93205 3.81549 1.39127 5.30626 0.245445 7.16161C0.0818293 7.44514 0 7.72468 0 8.00021C0 8.27575 0.0818293 8.55528 0.245445 8.83873C1.39127 10.6942 2.93205 12.1851 4.86775 13.311C6.8034 14.4373 8.84746 15 11 15C13.1526 15 15.1966 14.4391 17.1322 13.3171C19.0677 12.195 20.6088 10.7021 21.7546 8.83873C21.9182 8.55528 22 8.27587 22 8.00021C22 7.72468 21.9182 7.44514 21.7546 7.16161ZM8.36645 3.83771C9.09892 3.11263 9.97668 2.74996 10.9999 2.74996C11.1635 2.74996 11.3027 2.80683 11.4174 2.92018C11.5318 3.03358 11.5894 3.17137 11.5894 3.33338C11.5894 3.49551 11.5318 3.63322 11.4176 3.74644C11.3032 3.85997 11.1638 3.91667 11.0002 3.91667C10.2963 3.91667 9.6946 4.16389 9.19541 4.65801C8.69605 5.15242 8.44652 5.74784 8.44652 6.44457C8.44652 6.60679 8.38907 6.74441 8.27456 6.85789C8.15988 6.97146 8.02082 7.02812 7.85716 7.02812C7.69337 7.02812 7.55427 6.97133 7.43963 6.85789C7.32495 6.74441 7.26767 6.60662 7.26767 6.44457C7.26767 5.43191 7.63403 4.56283 8.36645 3.83771ZM16.3343 11.9684C14.6933 12.9525 12.9152 13.4449 11 13.4449C9.08482 13.4449 7.30676 12.9528 5.66579 11.9684C4.02481 10.9839 2.66003 9.66125 1.57153 8.00021C2.81556 6.08808 4.37471 4.65796 6.24896 3.71022C5.74972 4.5527 5.50002 5.46426 5.50002 6.44453C5.50002 7.94356 6.03825 9.22537 7.11442 10.2909C8.19058 11.3565 9.48592 11.8891 11 11.8891C12.5142 11.8891 13.8092 11.3562 14.8856 10.2909C15.962 9.22566 16.5 7.94356 16.5 6.44453C16.5 5.46426 16.2503 4.55257 15.751 3.71022C17.6252 4.65796 19.1844 6.0882 20.4284 8.00021C19.3401 9.66125 17.9754 10.9839 16.3343 11.9684Z"
        fill={defineColor()}
      />
      <line
        x1="18.5566"
        y1="1.41421"
        x2="4.41451"
        y2="15.5563"
        stroke={defineColor()}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default InputIconEye;
