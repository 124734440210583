import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';

import CloseButton from '../Buttons/CloseButton';
import Input from '../Inputs/Input';
import SubmitButton from '../Buttons/SubmitButton';
import { StoreContext } from '../../store';

import ResetPassword from './ResetPassword';
import Modal from './Modal';
import Schema from './ForgotPasswordSchema';
import style from './ForgotPassword.module.css';

type MyFormValues = {
  email: string;
};

function ForgotPassword(): JSX.Element {
  const { forgot } = React.useContext(StoreContext);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');

  function openForgot(): void {
    setStep(1);
  }
  function openReset(): void {
    setStep(2);
  }

  function handleSubmit(values: MyFormValues): void {
    setEmail(values.email);
    openReset();
  }

  function renderForm(): JSX.Element {
    return (
      <Form className={style.form}>
        <Field type="text" name="email" component={Input} placeholder={'Email'} icon="email" />
        <SubmitButton title="Отправить" className={style.button} />
      </Form>
    );
  }

  return (
    <Modal isForgot>
      <CloseButton close={forgot.close} />
      {step === 1 && (
        <>
          <h2 className={style.title}>Забыли пароль?</h2>
          <p>Просто укажите email, с которым Вы регистрировались в системе Innerica, и мы Вам поможем.</p>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={Schema}
            onSubmit={handleSubmit}
            render={renderForm}
          />
        </>
      )}
      {step === 2 && <ResetPassword openForgot={openForgot} email={email} />}
    </Modal>
  );
}

export default ForgotPassword;
