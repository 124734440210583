import React from 'react';

import { StoreContext } from '../../store';

import SignUp from './SignUp';
import SignIn from './SignIn';
import ForgotPassword from './ForgotPassword';
import CreateNewPassword from './CreateNewPassword';
import ContactUs from './ContactUs';
import VideoPopup from './VideoPopup';
import SuccessPopup from './SuccessPopup';
import FailedPopup from './FailedPopup';
import Preloader from './Preloader';

function PopupOpener(): JSX.Element | null {
  const { signUp, signIn, forgot, newPassword, contactUs, video, success, failed, preloader } = React.useContext(
    StoreContext,
  );

  if (signUp.isOpen) {
    return <SignUp />;
  }
  if (signIn.isOpen) {
    return <SignIn />;
  }
  if (forgot.isOpen) {
    return <ForgotPassword />;
  }
  if (newPassword.isOpen) {
    return <CreateNewPassword />;
  }
  if (contactUs.isOpen) {
    return <ContactUs />;
  }
  if (video.isOpen) {
    return <VideoPopup />;
  }
  if (success.isOpen) {
    return <SuccessPopup />;
  }
  if (failed.isOpen) {
    return <FailedPopup />;
  }
  if (preloader.isOpen) {
    return <Preloader />;
  }
  return null;
}

export default PopupOpener;
