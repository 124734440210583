import React from 'react';

type Props = {
  green?: boolean;
  white?: boolean;
};

function InputIconMan({ green, white }: Props): JSX.Element {
  function defineColor(): string {
    if (green) return 'var(--green)';
    else if (white) return '#FFFFFF';
    else return '#E5E5E5';
  }

  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8.99922" cy="4.8" r="3.95" stroke={defineColor()} strokeWidth="1.7" strokeLinecap="round" />
      <path
        d="M17 16C17 12.0236 13.4183 8.80005 9 8.80005C4.58172 8.80005 1 12.0236 1 16"
        stroke={defineColor()}
        strokeWidth="1.7"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default InputIconMan;
