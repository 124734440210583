import * as Yup from 'yup';

const SignUpStepThreeSchema = Yup.object().shape({
  unp: Yup.string().required('This field is required'),
  okpo: Yup.string().required('This field is required'),
  organizationAddress: Yup.string().required('This field is required'),
  deliveryAddress: Yup.string().required('This field is required'),
  bankName: Yup.string().required('This field is required'),
  bankCode: Yup.string().required('This field is required'),
  pc: Yup.string().required('This field is required'),
  ceoName: Yup.string().required('This field is required'),
});

export default SignUpStepThreeSchema;
