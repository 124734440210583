import React from 'react';

import video from '../../videos/promo.mp4';

import s from './VideoAbout.module.css';

function VideoAbout(): JSX.Element {
  return (
    <article className={s.container}>
      <h1>О проекте</h1>
      <video className={s.video} controls>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className={s.square} />
    </article>
  );
}

export default VideoAbout;
