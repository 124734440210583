import React from 'react';

import { StoreContext } from '../../store';

import ProductMock from './ProductMock.json';
import style from './Product.module.css';

type Product = {
  id: number;
  title: string;
  description: string;
};

function Product(): JSX.Element {
  const { contactUs } = React.useContext(StoreContext);

  function contactUsHandler(): void {
    contactUs.open();
  }

  return (
    <article className={style.container} id="product">
      <div className={style.cow} />
      <button className={style.learnMore} onClick={contactUsHandler}>
        Больше о партнерских программах
      </button>
      <div className={style.companies}>
        {ProductMock.map((item: Product) => {
          return (
            <div className={style.companiesItem} key={item.id}>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
              <button className={style.getOffer} onClick={contactUsHandler}>
                Получить предложение
              </button>
            </div>
          );
        })}
      </div>
    </article>
  );
}

export default Product;
