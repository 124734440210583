import React from 'react';
import cn from 'classnames';

import style from './Modal.module.css';

type Props = {
  children: React.ReactNode;
  signIn?: boolean;
  isForgot?: boolean;
  createPass?: boolean;
  contact?: boolean;
  className?: string;
};

function Modal({ children, signIn, isForgot, createPass, contact, className }: Props): JSX.Element {
  return (
    <div className={cn(style.background)}>
      <div className={cn(style.container)}>
        <div
          className={cn(
            style.content,
            signIn && style.signinContent,
            isForgot && style.forgotContent,
            createPass && style.createContent,
            contact && style.contactContent,
            className,
          )}
        >
          {!contact && (
            <div
              className={cn(
                style.image,
                signIn && style.signinImage,
                isForgot && style.forgotImage,
                createPass && style.createImage,
              )}
            />
          )}
          <div
            className={cn(
              style.form,
              signIn && style.signinForm,
              isForgot && style.forgotForm,
              createPass && style.createForm,
              contact && style.contactForm,
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
