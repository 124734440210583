import React from 'react';

import Main from '../components/Main';
import Advantages from '../components/Advantages';
import Order from '../components/Order';
import Info from '../components/Info';
import Product from '../components/Product';
import Clients from '../components/Clients';
import Calculator from '../components/Calculator';
import Equipment from '../components/Equipment';
import Review from '../components/Review';
// import FAQ from '../components/FAQ';
import data from '../data.json';
import VideoAbout from '../components/VideoAbout';

function HomePage(): JSX.Element {
  const hostname = window.location.hostname;
  const isBY = hostname.endsWith('.by');
  return (
    <>
      <Main />
      <VideoAbout />
      <Advantages />
      <Order number={data.number} />
      <Info />
      <Product />
      {isBY ? null : <Clients />}
      {isBY ? null : <Calculator />}
      <Equipment equipment={data.equipment} />
      {/* <div style={{ boxShadow: 'inset 0px 0px 19px rgba(0, 0, 0, 0.25)' }}> */}
      {isBY ? null : <Review />}
      {/* <FAQ /> */}
      {/* </div> */}
    </>
  );
}

export default HomePage;
