import React, { useState } from 'react';

import Convenience from '../../images/AdvantagesConvenience.png';
import Reliability from '../../images/AdvantagesReliability.png';
import Functionality from '../../images/AdvantagesFunctionality.png';
import Clients from '../../images/AdvantagesClients.png';

import AdvantagesMenu from './AdvantagesMenu';
import AdvantagesBody from './AdvantagesBody';
import advantagesMock from './advantagesMock.json';
import style from './Advantages.module.css';

function Advantages(): JSX.Element {
  const [advantageIndex, setAdvantageIndex] = useState(0);
  const images = [
    {
      src: Convenience,
      title: 'Удобство',
      alt: 'Телеметрия (1 скважина)',
    },
    {
      src: Reliability,
      title: 'Надежность',
      alt: 'Мониторинг с виджетами',
    },
    {
      src: Functionality,
      title: 'Функциональность',
      alt: 'Приборная панель',
    },
    {
      src: Clients,
      title: 'Клиентоориентированность',
      alt: 'Настройка шаблонов',
    },
  ];

  return (
    <article className={style.container} id="advantages">
      <AdvantagesMenu
        advantages={advantagesMock}
        active={advantageIndex}
        setActive={setAdvantageIndex}
        className={style.header}
      />
      <AdvantagesBody advantage={advantagesMock[advantageIndex]} active={advantageIndex} className={style.body} />
      {/* <div className={style.shadow} /> */}
      <img
        src={images[advantageIndex].src}
        alt={images[advantageIndex].alt}
        title={images[advantageIndex].title}
        className={style.image}
      />
    </article>
  );
}

export default Advantages;
