import React from 'react';
import cn from 'classnames';

import style from './Preloader.module.css';

function Preloader(): JSX.Element {
  return (
    <div className={cn(style.background)}>
      <div className={cn(style.container)}>
        <svg
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={style.preloader}
        >
          <rect x="0" y="0" width="10" height="10" rx="2" fill="white" />
          <rect x="50" y="0" width="10" height="10" rx="2" fill="white" />
          <rect x="50" y="50" width="10" height="10" rx="2" fill="white" />
          <rect x="0" y="50" width="10" height="10" rx="2" fill="white" />
        </svg>
      </div>
    </div>
  );
}

export default Preloader;
