import React from 'react';
import { Formik, Field, Form } from 'formik';

import Input from '../Inputs/Input';
import SubmitButton from '../Buttons/SubmitButton';

import Schema from './SignUpStepThreeSchema';
import style from './SignUpStepThree.module.css';

type MyFormValues = {
  unp: string;
  okpo: string;
  organizationAddress: string;
  deliveryAddress: string;
  bankName: string;
  bankCode: string;
  pc: string;
  ceoName: string;
};

type Props = {
  close: () => void;
};

function SignUpStepThree({ close }: Props): JSX.Element {
  const handleSubmit = (values: MyFormValues): void => {
    close();
  };

  return (
    <>
      <h2 className={style.title}>Добавьте реквизиты</h2>
      <Formik
        initialValues={{
          unp: '',
          okpo: '',
          organizationAddress: '',
          deliveryAddress: '',
          bankName: '',
          bankCode: '',
          pc: '',
          ceoName: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={Schema}
        render={(): JSX.Element => (
          <Form className={style.form}>
            <Field type="text" name="unp" component={Input} placeholder={'УНП'} icon="card" />
            <Field type="text" name="okpo" component={Input} placeholder={'ОКПО'} icon="card" />
            <Field
              type="text"
              name="organizationAddress"
              component={Input}
              placeholder={'Адрес организации'}
              icon="address"
            />
            <Field
              type="text"
              name="deliveryAddress"
              component={Input}
              placeholder={'Адрес доставки документов'}
              icon="address"
            />
            <Field type="text" name="bankName" component={Input} placeholder={'Название банка'} icon="card" />
            <Field type="text" name="bankCode" component={Input} placeholder={'Код банка'} icon="card" />
            <Field type="text" name="pc" component={Input} placeholder={'р/с'} icon="card" />
            <Field type="text" name="ceoName" component={Input} placeholder={'ФИО директора'} icon="man" />
            <SubmitButton title="Сохранить и продолжить" className={style.button} />
          </Form>
        )}
      />
    </>
  );
}

export default SignUpStepThree;
