import React from 'react';

type Props = {
  green?: boolean;
  white?: boolean;
};

function InputIconEmail({ green, white }: Props): JSX.Element {
  function defineColor(): string {
    if (green) return 'var(--green)';
    else if (white) return '#FFFFFF';
    else return '#E5E5E5';
  }

  return (
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.3143 0H2.69025C1.20629 0 0 1.1528 0 2.57096V13.429C0 14.8472 1.20629 16 2.69025 16H19.3097C20.7937 16 22 14.8472 22 13.429V2.57531C22.0045 1.15715 20.7983 0 19.3143 0ZM20.7755 13.429C20.7755 14.199 20.12 14.8254 19.3143 14.8254H2.69025C1.88454 14.8254 1.22905 14.199 1.22905 13.429V2.57531C1.22905 1.80533 1.88454 1.1789 2.69025 1.1789H19.3097C20.1154 1.1789 20.7709 1.80533 20.7709 2.57531V13.429H20.7755Z"
        fill={defineColor()}
      />
      <path
        d="M13.8758 7.86502L19.2563 3.25381C19.5067 3.0363 19.5294 2.66654 19.3018 2.42293C19.0742 2.18367 18.6873 2.16192 18.4324 2.37943L11.0126 8.74376L9.56502 7.5083C9.56047 7.50395 9.55592 7.4996 9.55592 7.49525C9.52405 7.4648 9.49219 7.4387 9.45577 7.4126L3.56544 2.37508C3.31052 2.15757 2.9236 2.17932 2.696 2.42293C2.4684 2.66654 2.49116 3.0363 2.74607 3.25381L8.19031 7.90417L2.76883 12.7546C2.52302 12.9765 2.50937 13.3463 2.74152 13.5855C2.86442 13.7073 3.0283 13.7726 3.19217 13.7726C3.34239 13.7726 3.49261 13.7204 3.61096 13.616L9.11437 8.6959L10.6074 9.97051C10.7258 10.0706 10.8715 10.1184 11.0171 10.1184C11.1628 10.1184 11.313 10.0662 11.4268 9.96616L12.9608 8.6524L18.4324 13.6203C18.5507 13.7291 18.7055 13.7813 18.8557 13.7813C19.0196 13.7813 19.1789 13.7204 19.3018 13.5986C19.534 13.3637 19.5249 12.9895 19.2791 12.7677L13.8758 7.86502Z"
        fill={defineColor()}
      />
    </svg>
  );
}

export default InputIconEmail;
