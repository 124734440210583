import React, { useState } from 'react';

import CloseButton from '../Buttons/CloseButton';
import { StoreContext } from '../../store';

import Modal from './Modal';
import SignUpStepOne from './SignUpStepOne';
import SignUpStepTwo from './SignUpStepTwo';
import SignUpStepThree from './SignUpStepThree';

function SignUp(): JSX.Element {
  const [step, setStep] = useState(1);

  const { signUp, signIn } = React.useContext(StoreContext);

  function handleContinue(): void {
    setStep(step + 1);
  }

  return (
    <Modal>
      <CloseButton close={signUp.close} />
      {step === 1 && <SignUpStepOne handleContinue={handleContinue} openSignIn={signIn.open} />}
      {step === 2 && <SignUpStepTwo handleContinue={handleContinue} />}
      {step === 3 && <SignUpStepThree close={signUp.close} />}
    </Modal>
  );
}

export default SignUp;
