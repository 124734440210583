import React from 'react';

import video from '../../images/InfoHeader.mp4';

import style from './InfoHeader.module.css';

type Props = {
  text: string;
  description: string;
  className?: string;
};

function InfoHeader({ text, description }: Props): JSX.Element {
  return (
    <section className={style.container}>
      {/* <img className={style.image} src={image} alt="" /> */}
      <video
        className={style.image}
        // muted
        // playsinline=""
        // autoplay
        // style="object-fit: cover; background-size: cover; width: 100%; height: 174.757%; position: absolute; left: 0px; top: -37.3786%; visibility: inherit; opacity: 1; display: block;"
        // class="resizelistener"
        // loop="true"
        // preload="auto"
        // controls
        autoPlay
        data-autoplay
        loop
        muted
        playsInline
      >
        <source src={video} type="video/mp4" />
      </video>
      <div className={style.text}>
        <h2 className={style.header}>{text}</h2>
        <p className={style.description}>{description}</p>
      </div>
    </section>
  );
}

export default InfoHeader;
