import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';

function InfoTechnology(): JSX.Element {
  const [isDisplay, setDisplay] = useState(false);

  function display(): void {
    setDisplay(true);
  }

  return (
    <Waypoint onEnter={display} bottomOffset={300}>
      <svg viewBox="0 0 741 423" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="maskInfoTechnology" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="741" height="423">
          <rect width="741" height="423" rx="1" fill="#B0CBEA" />
        </mask>
        {isDisplay && (
          <g mask="url(#maskInfoTechnology)">
            <svg
              width="599"
              height="238"
              viewBox="0 0 599 238"
              x="84"
              y="423"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <animate attributeName="y" from="423" to="89" dur="500ms" begin="500ms" fill="freeze" />
              <mask
                id="maskInfoTechnology0"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="495"
                y="120"
                width="99"
                height="99"
              >
                <path
                  d="M563.979 198.831C580.185 188.067 584.596 166.202 573.831 149.997C563.067 133.791 541.203 129.38 524.997 140.144C508.791 150.909 504.38 172.773 515.145 188.979C525.909 205.185 547.774 209.596 563.979 198.831Z"
                  fill="#918DFF"
                />
              </mask>
              <g strokeWidth="15">
                <circle r="35" cx="545" cy="170" stroke="none" fill="#fff" />
                <circle r="27.5" cx="545" cy="170" stroke="#D2DCF9" strokeDasharray="0 190">
                  <animate
                    attributeName="stroke-dasharray"
                    begin="1s"
                    from="0 190"
                    to="190 190"
                    dur="0.5s"
                    fill="freeze"
                  />
                </circle>
                <circle r="27.5" cx="545" cy="170" stroke="#4455A1" strokeDasharray="0 190">
                  <animate
                    attributeName="stroke-dasharray"
                    begin="1s"
                    from="0 190"
                    to="110 190"
                    dur="0.5s"
                    fill="freeze"
                  />
                </circle>
                <circle r="27.5" cx="545" cy="170" stroke="#688AEA" strokeDasharray="0 190">
                  <animate
                    attributeName="stroke-dasharray"
                    begin="1s"
                    from="0 190"
                    to="42 190"
                    dur="0.5s"
                    fill="freeze"
                  />
                </circle>
              </g>
              <path d="M521.434 215.591H570.7" stroke="#D2DCF9" strokeMiterlimit="10" />
              <path d="M521.434 226.389H570.7" stroke="#D2DCF9" strokeMiterlimit="10" />
              <path d="M521.434 237.187H570.7" stroke="#D2DCF9" strokeMiterlimit="10" />
              <path d="M307 50.001H417.685" stroke="#D2DCF9" strokeMiterlimit="10" />
              <path d="M307 57.6082H417.685" stroke="#D2DCF9" strokeMiterlimit="10" />
              <path d="M307 65.2151H417.685" stroke="#D2DCF9" strokeMiterlimit="10" />
              <path d="M307 72.822H417.685" stroke="#D2DCF9" strokeMiterlimit="10" />
              <path d="M307 80.4036H417.685" stroke="#D2DCF9" strokeMiterlimit="10" />
              <path d="M307 88.0105H417.685" stroke="#D2DCF9" strokeMiterlimit="10" />
              <path d="M307 97.9917H417.685" stroke="#D2DCF9" strokeMiterlimit="10" />
              <path d="M307 105.42H417.685" stroke="#D2DCF9" strokeMiterlimit="10" />
              <path d="M307 112.874H417.685" stroke="#D2DCF9" strokeMiterlimit="10" />
              <path d="M307 120.302H417.685" stroke="#D2DCF9" strokeMiterlimit="10" />
              <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="176" y="0" width="56" height="56">
                <path
                  d="M228.108 31.8918C230.258 18.5771 221.206 6.04111 207.892 3.89176C194.577 1.74242 182.041 10.7937 179.892 24.1083C177.742 37.4229 186.794 49.9589 200.108 52.1083C213.423 54.2576 225.959 45.2064 228.108 31.8918Z"
                  fill="#918DFF"
                />
              </mask>
              <g strokeWidth="24">
                <circle r="12" cx="204" cy="27" stroke="#4455A1" strokeDasharray="0 80">
                  <animate
                    attributeName="stroke-dasharray"
                    from="0 80"
                    to="80 80"
                    begin="1s"
                    dur="0.5s"
                    fill="freeze"
                  />
                </circle>
                <circle r="12" cx="204" cy="27" stroke="#D2DCF9" strokeDasharray="0 80">
                  <animate
                    attributeName="stroke-dasharray"
                    from="0 80"
                    to="65 80"
                    begin="1s"
                    dur="0.5s"
                    fill="freeze"
                  />
                </circle>
                <circle r="12" cx="204" cy="27" stroke="#688AEA" strokeDasharray="0 80">
                  <animate
                    attributeName="stroke-dasharray"
                    from="0 80"
                    to="50 80"
                    begin="1s"
                    dur="0.5s"
                    fill="freeze"
                  />
                </circle>
              </g>
              <line x1="19" y1="178" x2="19" y2="178" stroke="#D2E1F9" strokeWidth="13">
                <animate attributeName="y2" from="178" to="150" begin="1s" dur="0.5s" fill="freeze" />
              </line>
              <line x1="39" y1="178" x2="39" y2="178" stroke="#D2E1F9" strokeWidth="13">
                <animate attributeName="y2" from="168" to="160" begin="1s" dur="0.5s" fill="freeze" />
              </line>
              <line x1="58" y1="178" x2="58" y2="178" stroke="#D2E1F9" strokeWidth="13">
                <animate attributeName="y2" from="178" to="152" begin="1s" dur="0.5s" fill="freeze" />
              </line>
              <line x1="77" y1="178" x2="77" y2="178" stroke="#D2E1F9" strokeWidth="13">
                <animate attributeName="y2" from="178" to="133" begin="1s" dur="0.5s" fill="freeze" />
              </line>
              <path
                d="M12.8652 136.066L26.0371 131.446L38.7495 136.066L71.7302 109.518"
                stroke="#D2E1F9"
                strokeMiterlimit="10"
              />
              <path d="M77.3462 105L72.0366 114.598L71.3219 109.85L66.8291 108.14L77.3462 105Z" fill="#D2E1F9" />
              <path d="M0 183.444H96.9766V124.426" stroke="#D2E1F9" strokeWidth="0.25" strokeMiterlimit="10" />
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" y="423" width="741" height="307" fill="none">
              <animate attributeName="y" from="423" to="117" dur="500ms" fill="freeze" />
              <defs />
              <path
                fill="#2C2C2C"
                d="M432.219 148.486l-4.076.198-.966 1.933 37.262 73.557 16.884-6.02-49.104-69.668z"
              />
              <path
                fill="#E5E5E5"
                d="M481.323 218.154l-16.884 6.02-37.262-73.557.966-1.933 37.658 73.161 15.72-6.02-.198 2.329z"
              />
              <path fill="white" d="M472.788 145.19l-43.48 1.549 37.262 74.524 27.178-12.028-20.96-64.045z" />
              <path
                fill="#FA92AE"
                d="M439.521 180.158c2.224-.898 4.351-2.022 6.346-3.353 1.316-.885 4.053-2.399 3.237-4.39-.073-.185-.18-.354-.314-.501-.197-.181-.426-.323-.675-.419-.842-.317-1.76-.37-2.632-.151-2.329.512-4.332 2.212-6.148 3.609-1.049.656-1.864 1.626-2.329 2.772-.338 1.164.454 2.748 1.688 2.666.285-.04.563-.118.827-.233zM442.046 180.962c-1.363.792-2.865 1.758-3.191 3.307-.098.328-.093.679.016 1.004.108.325.314.609.59.812.332.163.703.227 1.071.187 1.057-.026 2.1-.252 3.074-.664-.566.674-.965 1.472-1.164 2.329-.085.432-.014.88.199 1.265.213.386.554.684.965.843.492.098 1.003.05 1.467-.14 1.449-.444 2.853-1.025 4.192-1.735.997-.483 1.888-1.16 2.62-1.991.287-.177.533-.413.719-.693.187-.28.311-.598.363-.93.053-.333.032-.673-.06-.997-.091-.324-.252-.624-.472-.88-.219-.256-.491-.461-.797-.602-.306-.14-.639-.213-.976-.212-.336.001-.669.074-.975.216-.305.142-.576.348-.794.604.906-.614 1.537-1.559 1.758-2.631.156-.643.078-1.32-.221-1.91-1.503-2.608-6.731 1.851-8.384 2.818zM451.666 189.253c.785-.336 1.653-.429 2.492-.268.414.095.778.34 1.021.689.244.349.348.776.294 1.197-.186.711-.92 1.106-1.583 1.421-1.578.816-3.27 1.389-5.019 1.7-.557.105-1.131.081-1.677-.07-1.49-.454-1.665-1.782-.442-2.469 1.568-.879 3.213-1.616 4.914-2.2z"
              />
              <path
                stroke="#2C2C2C"
                strokeLinejoin="round"
                d="M452.528 150.559l-3.587-5.24 11.703-.431 1.572 4.623-9.688 1.048z"
              />
              <path
                stroke="#E5E5E5"
                strokeMiterlimit="10"
                d="M440.952 157.603l31.254-4.075M443.28 161.492l30.474-4.657M445.227 165.754l28.726-6.008M448.335 170.028l27.364-6.602M450.664 174.302l27.364-7.383M452.597 177.981l27.562-7.371M460.562 193.899l21.926-6.602M462.494 197.392l21.74-6.206M464.824 201.666l18.445-6.218"
              />
              <g opacity=".2">
                <path
                  fill="black"
                  d="M479.482 215.546l2.201-.978c0-12.809-.361-31.103-.524-33.548-.357-5.054-.889-10.099-1.595-15.138l-5.636-17.198c-1.386 1.77-2.422 5.729-2.888 6.986-2.888 7.72-4.867 16.244-2.445 24.36.873 2.935 2.328 5.683 3.213 8.594 1.584 4.891 1.77 10.119 1.759 15.266 0 5.775-.256 11.539-.769 17.292l8.524-3.039-1.84-2.597z"
                  opacity=".2"
                />
              </g>
              <path
                fill="#4455A1"
                d="M481.662 132.184c-1.366 1.176-2.473 2.623-3.249 4.25-4.494 8.303-6.229 17.769-7.895 27.073-.733 4.134-1.478 8.361-.908 12.53.71 5.158 3.389 9.816 5.1 14.742 6.16 17.676-.337 36.889-3.272 55.38-.212.516-.296 1.076-.242 1.631.053.555.241 1.089.547 1.555.307.466.722.85 1.211 1.119.489.269 1.036.414 1.593.423 21.168 5.446 43.53 3.894 63.742-4.425 1.448-.458 2.733-1.323 3.703-2.492.787-1.417 1.105-3.048.908-4.657-.361-12.46-4.902-24.361-7.068-36.634-3.493-19.714-.734-39.917 0-59.922.14-3.808.14-7.86-1.875-11.085-4.005-6.393-13.216-6.195-20.68-5.229-5.822.757-11.645 1.91-17.327 2.83-4.576.768-10.445-.14-14.288 2.911z"
              />
              <path
                fill="#543A18"
                d="M454.879 62.8992c-1.874 5.0769-1.49 11.3533 2.329 15.1377 1.607 1.6186 3.738 2.7597 4.902 4.716 1.409 2.3289 1.083 5.3797.163 7.9648-.919 2.585-2.387 4.9605-3.19 7.5805-2.213 7.1148.745 14.9518 5.333 20.8318 1.504 2.084 3.428 3.829 5.648 5.124 3.109 1.653 6.765 1.851 10.282 1.7 7.638-.315 15.44-2.224 21.565-6.801 9.106-6.8 13.205-18.305 16.034-29.3087 2.737-10.643 4.658-22.1942.583-32.3947-2.121-4.9652-5.486-9.3005-9.769-12.5881-4.282-3.2877-9.34-5.417-14.685-6.1827-16.034-2.2357-33.594 9.0826-39.195 24.2204z"
              />
              <path
                fill="#313C6F"
                d="M454.879 62.8992c-1.874 5.0769-1.49 11.3533 2.329 15.1377 1.607 1.6186 3.738 2.7597 4.902 4.716 1.409 2.3289 1.083 5.3797.163 7.9648-.919 2.585-2.387 4.9605-3.19 7.5805-2.213 7.1148.745 14.9518 5.333 20.8318 1.504 2.084 3.428 3.829 5.648 5.124 3.109 1.653 6.765 1.851 10.282 1.7 7.638-.315 15.44-2.224 21.565-6.801 9.106-6.8 13.205-18.305 16.034-29.3087 2.737-10.643 4.658-22.1942.583-32.3947-2.121-4.9652-5.486-9.3005-9.769-12.5881-4.282-3.2877-9.34-5.417-14.685-6.1827-16.034-2.2357-33.594 9.0826-39.195 24.2204z"
              />
              <path
                fill="#FA92AE"
                d="M497.813 101.326c2.31 4.187 3.673 8.83 3.994 13.601.373 3.679.943 9.432-.652 12.89-1.293 2.83-4.588 4.064-7.557 5.031.582 1.35 2.247 1.781 3.703 2.026 3.517.709 7.102 1.025 10.689.943 4.001-.276 7.86-1.597 11.19-3.831.457-.219.856-.542 1.165-.943.306-.58.443-1.233.396-1.887.163-11.481.33-22.966.5-34.4556l-23.428 6.6256z"
              />
              <path fill="black" d="M519.996 97.3203l-20.588 7.2547s8.827 7.138 20.588-7.2547z" opacity=".2" />
              <path
                fill="#FA92AE"
                d="M482.709 104.039c4.46 2.085 10.224 1.945 14.824 1.409 7.764-.781 15.237-3.369 21.821-7.557 10.282-6.7654 14.498-16.2091 16.396-27.9466 1.781-11.0389 2.259-24.2321-4.914-33.5942-3.614-4.4797-8.234-8.0425-13.485-10.3984-1.733-.8412-3.527-1.5497-5.368-2.1193-23.289-6.9867-42.199 15.9179-41.407 37.5882.361 9.7813-.117 19.877 2.806 29.3439 1.293 4.2153 3.493 9.2454 7.115 11.9704.69.51 1.432.947 2.212 1.304z"
              />
              <g style={{ mixBlendMode: 'multiply' }}>
                <path fill="#FA92AE" d="M481.111 73.6807l-2.806 13.964 7.822-1.0527-5.016-12.9113z" />
              </g>
              <path
                fill="black"
                d="M530.393 80.6337c1.874 2.224 3.493 3.0159 4.82 3.0391.466-1.1644.92-2.4103 1.363-3.633l-6.183.5939z"
                opacity=".2"
              />
              <path
                fill="#FCF4E6"
                d="M528.367 62.4219c.876-1.1133 2.06-1.944 3.405-2.3882 1.345-.4443 2.791-.4825 4.157-.1098 1.367.3727 2.593 1.1397 3.526 2.2052.933 1.0656 1.532 2.3824 1.721 3.7861.125 2.232-.353 4.4565-1.386 6.4394-.628 1.6294-1.597 3.1061-2.841 4.3317-1.161.9933-2.601 1.6036-4.123 1.7474-1.521.1439-3.05-.1858-4.377-.944l-.082-15.0678z"
              />
              <path
                fill="black"
                d="M534.84 69.5366c-.139 1.7467-1.071 3.1091-2.084 3.0276-1.013-.0815-1.7-1.572-1.56-3.3187.139-1.7467 1.071-3.1091 2.072-3.0392 1.002.0699 1.712 1.572 1.572 3.3303z"
                opacity=".2"
              />
              <path
                fill="#313C6F"
                d="M501.049 58.5792c3.595 1.3086 7.36 2.093 11.179 2.3289 2.562.1514 6.008.8733 6.008 3.4933 0 1.5254-1.362 2.6317-2.433 3.7379-5.089 5.2516-3.785 15.1377 2.492 18.9454 1.016.4779 1.942 1.1279 2.736 1.9214 1.805 2.2473.256 5.4961-1.164 8.0346-2.577 4.4273-4.017 9.4243-4.192 14.5443 0 5.1 1.979 10.351 5.962 13.53 3.714 2.97 8.698 3.855 13.449 3.948 10.748.209 21.973-3.493 28.948-11.645 6.975-8.151 8.57-21.1924 2.084-29.751-1.77-2.3289-4.029-4.2619-5.671-6.6723-3.015-4.4016-1.164-9.6648-3.656-14.474-2.049-4.029-4.728-5.8222-8.698-7.336-3.971-1.5137-3.028-3.5632-2.073-7.4757.827-3.4933.314-6.9866-.21-10.48-.306-3.3333-1.156-6.5941-2.515-9.6532-3.202-6.288-10.212-9.595-16.942-11.6444-2.311-.8264-4.711-1.3813-7.15-1.6535-9.176-.6521-20.32.3261-28.82 3.9125-4.437 1.8748-8.896 4.7626-10.48 9.3156-2.026 5.7057 1.164 12.0054 5.228 16.4652 4.379 4.7608 9.839 8.3991 15.918 10.608z"
              />
              <path
                fill="black"
                d="M534.2 171.67l-4.832 3.365-10.411 15.673c-1.35 2.05-3.202 4.344-5.647 4.134-1.474-.291-2.804-1.076-3.773-2.224-1.514-1.456-2.958-2.993-4.332-4.576-2.538-5.823-4.168-12.087-6.369-18.061-4.201-11.396-10.598-21.857-18.829-30.788.078 2.352.563 4.672 1.432 6.859 5.147 15.433 10.29 30.861 15.429 46.286 1.572 4.74 3.179 9.549 5.939 13.718 2.759 4.168 6.823 7.708 11.714 8.71 2.52.608 5.172.358 7.534-.711.911-.101 1.789-.399 2.573-.873 1.332-.954 2.359-2.272 2.958-3.796l-.303-.233c3.004-4.658 4.262-11.342 4.774-16.454.711-7.033.035-14.287 2.143-21.029z"
                opacity=".2"
              />
              <path
                fill="#4455A1"
                d="M537.938 159.397c-2.329 8.64-5.822 17.012-6.987 25.885-.57 4.367-.547 8.803-1.444 13.112-.896 4.308-2.841 8.64-6.439 11.167-3.598 2.527-9.094 2.655-12.087-.571-2.329-2.492-2.631-6.23-2.329-9.606.513-5.31 2.329-10.387 3.633-15.534 3.633-13.973 4.064-28.436 4.658-42.793.175-3.913.478-8.151 2.935-11.144 4.937-6.125 14.101-2.981 17.687 2.981 4.507 7.382 2.446 18.759.373 26.503z"
              />
              <path
                fill="black"
                d="M545.112 44.0704c-20.148 6.4924-40.867 11.0581-61.878 13.6356-9.84 1.2576-16.373 3.2721-25.374-.885 4.169-6.8585 7.429-8.3956 13.1-11.9704 2.55-1.6932 5.19-3.248 7.907-4.6578 1.091-.5982 2.27-1.0188 3.493-1.2459 3.412-.524 6.789 1.4905 10.236 1.1644l52.516 3.9591z"
                opacity=".2"
              />
              <path
                fill="#F7C92B"
                d="M546.812 43.9306s.326-45.57623-51.41-42.58361c0 0-34.934 6.50923-34.072 47.90511l85.482-5.3215z"
              />
              <path
                fill="#F2B51D"
                d="M546.812 43.9307s-107.361 15.5802-97.219 10.084c3.701-2.0652 7.644-3.6648 11.737-4.7625l85.482-5.3215z"
              />
              <path
                fill="#DEAA30"
                d="M479.11 48.856l1.305-.8151s-13.962-35.2127 30.496-46.00707V.869385l-9.688.698665-12.296 4.78585-4.658 4.6578-6.137 6.2297-4.227 7.7203-.116 4.9838-.477 7.4058 5.798 11.5047z"
              />
              <path
                fill="#F7E249"
                d="M510.877.869528S465.848 5.84169 479.111 48.8562l-9.699.5589S455.59 9.2535 499.687.508545l11.19.360983z"
              />
              <path
                fill="black"
                fillOpacity=".7"
                d="M488.53 70.445c.187 1.4905-.221 2.7713-.908 2.8528-.687.0816-1.409-1.0479-1.595-2.5384-.187-1.4905.233-2.7714.92-2.8529.687-.0815 1.397 1.048 1.583 2.5385zM475.513 71.5858c.186 1.4904-.222 2.7713-.909 2.8528-.687.0815-1.409-1.048-1.595-2.5385-.186-1.4904.233-2.7597.92-2.8528.687-.0932 1.397 1.0596 1.584 2.5385z"
                opacity=".65"
              />
              <path
                fill="black"
                fillOpacity=".3"
                d="M473.077 65.3916c.896-.7123 1.96-1.1883 3.094-1.3835 1.134-.1951 2.3-.103 3.389.2678.227.0581.468.0295.674-.0801.206-.1096.363-.292.439-.5109.075-.2188.064-.4579-.031-.6694-.096-.2114-.268-.3796-.484-.471-1.399-.4693-2.896-.5812-4.35-.3252-1.454.2559-2.818.8714-3.964 1.7889-.929.7339.305 2.0977 1.24 1.3773l-.007.0061zM492.322 63.3748c-.736-.809-1.652-1.4023-2.663-1.7245-1.012-.3221-2.085-.3625-3.119-.1174-.212.0284-.426-.0295-.599-.1624s-.293-.3311-.336-.5554c-.043-.2244-.006-.4586.103-.6566.11-.198.284-.3453.489-.4129 1.327-.3075 2.703-.2491 3.998.1696 1.295.4188 2.467 1.1842 3.407 2.2248.764.8341-.515 2.0495-1.286 1.2279l.006.0069z"
              />
              <g opacity=".35">
                <path
                  fill="black"
                  fillOpacity=".3"
                  d="M486.087 92.9767c.153.174.262.3825.317.6076.054.2251.054.4601-.002.685-.102.4182-.339.7909-.676 1.0596-.336.2515-.744.3901-1.164.3959-.23.0009-.457-.055-.66-.1627-.203-.1076-.376-.2637-.505-.4544v-.1165c.594-.0137 1.166-.2183 1.634-.5832.468-.365.806-.8709.963-1.4429l.093.0116z"
                  opacity=".35"
                />
              </g>
              <path
                fill="#FCF4E6"
                d="M477.666 109.396c-.046-.78.111-1.558.454-2.259.165-.363.436-.669.776-.876.341-.208.736-.308 1.134-.289 1.013.14 1.595 1.165 2.014 2.143l3.494 7.766c.188.297.307.632.347.981.04.349.001.702-.114 1.034-.501.908-5.52-2.119-6.044-2.562-1.653-1.327-2.061-3.889-2.061-5.938z"
              />
              <path
                fill="#FA92AE"
                d="M477.666 109.396c-.046-.78.111-1.558.454-2.259.165-.363.436-.669.776-.876.341-.208.736-.308 1.134-.289 1.013.14 1.595 1.165 2.014 2.143l3.494 7.766c.188.297.307.632.347.981.04.349.001.702-.114 1.034-.501.908-5.52-2.119-6.044-2.562-1.653-1.327-2.061-3.889-2.061-5.938z"
              />
              <path
                fill="#2C2C2C"
                d="M480.31 117.837l-2.248-.617 5.532-20.3308.873-3.2488 2.247.6171-.885 3.2838-5.519 20.2957z"
              />
              <path fill="#D94061" d="M483.595 96.8892l.873-3.2488 2.247.6171-.885 3.2838-2.235-.6521z" />
              <path
                fill="#FA92AE"
                d="M477.597 111.037c-1.735-.885-3.563-1.478-4.996-.524-.41.316-.745.719-.981 1.18-.236.461-.366.969-.381 1.487-.017 1.04.124 2.077.419 3.074-.815-.116-1.502.641-1.758 1.421-.226.767-.194 1.588.092 2.335.286.747.81 1.379 1.491 1.798-.33.149-.626.364-.87.632-.243.268-.429.584-.545.927-.115.344-.159.707-.128 1.068.031.361.136.711.309 1.03.391.624.942 1.132 1.595 1.471.653.34 1.385.5 2.12.462-1.165.617-1.095 2.55-.093 3.493 1.001.943 3.493.629 4.238 1.747.282.573.388 1.218.303 1.851-.194 2.45.46 4.892 1.851 6.917.723.982 1.745 1.703 2.913 2.054 1.167.35 2.418.312 3.562-.109 2.794-1.305 3.4-4.961 3.4-8.035 0-5.17-.152-12.46-2.562-17.152-1.316-2.585-3.936-3.971-6.451-5.147-.978-.466-2.224-1.304-3.528-1.98z"
              />
              <path
                fill="#FA92AE"
                d="M482.255 142.664c11.155 19.737 15.848 42.444 25.059 63.159.659 1.931 1.874 3.625 3.493 4.867 1.362.775 2.944 1.074 4.495.85 2.764-.426 5.251-1.918 6.929-4.155s2.413-5.042 2.049-7.815c-.435-1.957-1.161-3.837-2.155-5.578-10.176-20.076-21.422-39.593-33.687-58.466l-6.183 7.138z"
              />
              <path
                fill="#F9F9F9"
                d="M353.478 122.763s-11.109 14.66-35.562 20.483l10.48 99.757 78.018.583 12.226-100.725c-11.259-4.215-21.819-10.103-31.323-17.466l-33.839-2.632z"
              />
              <path
                fill="#2C2C2C"
                d="M341.381 133.08c-7.136 4.79-15.088 8.235-23.463 10.166v.116c-6.649 3.272-25.979 61.145-28.913 68.702-3.121 8.035-6.277 16.233-6.987 24.884-.19 1.176-.065 2.381.361 3.494 1.025 2.107 3.668 2.515 5.915 2.643l24.523 1.467c1.324.226 2.684-.022 3.843-.698 1.07-1.084 1.708-2.521 1.793-4.041 1.068-7.159 1.764-14.369 2.085-21.6.71-11.424 1.409-22.87 1.642-34.317l6.218 59.142 32.988 2.329c-7.767-22.52-7.906-122.569-7.906-122.569-3.603 3.904-7.665 7.357-12.099 10.282zM454.484 236.925c-.711-8.686-3.843-16.907-6.929-24.977-2.969-7.744-22.753-67.852-28.983-69.11-7.159-2.939-14.041-6.513-20.564-10.678-3.365-2.13-6.928-4.75-10.48-7.522l-.104 4.658c-3.878 43.084-15.033 115.478-15.033 115.478l34.06-1.165 8.151-67.386v2.201c.081 13.239.885 26.467 1.688 39.672.317 7.258 1.009 14.495 2.073 21.682.077 1.525.706 2.969 1.77 4.064 1.142.675 2.488.923 3.796.699l24.29-1.467c2.224-.14 4.844-.536 5.822-2.667.45-1.102.603-2.302.443-3.482z"
              />
              <path
                fill="#FA92AE"
                d="M357.147 97.0642c-.384 4.9028.14 9.8278 0 14.7538-.14 4.925-.873 10.014-3.575 14.124 6.416 4.239 14.637 4.239 22.311 4.099 3.924-.07 8.151-.233 11.283-2.55.169-.094.316-.22.434-.372.118-.153.204-.327.253-.513-.011-.224-.068-.443-.168-.643-.101-.201-.241-.378-.414-.521-2.873-3.482-4.691-7.712-5.24-12.192-.549-4.45-.674-8.941-.372-13.4144l-24.512-2.7714z"
              />
              <path fill="black" d="M357.415 103.166l23.801 1.432s-11.621 10.096-23.801-1.432z" opacity=".2" />
              <path
                fill="#FA92AE"
                d="M344.06 87.3524c.494 2.4473 1.276 4.8276 2.328 7.0915 3.61 7.4871 11.412 9.5371 19.144 10.1421 4.463.34 8.943.406 13.414.198 2.568.004 5.117-.438 7.534-1.304 6.987-2.9 9.805-11.2835 11.237-18.7592 1.956-10.1289 2.993-20.414 3.097-30.7296 0-5.1003-.139-10.2937-1.793-15.1378-4.658-13.6123-21.309-21.1695-35.143-17.222-11.33 3.2371-20.005 13.0999-23.288 24.2203-2.609 8.7916-.711 18.4914 1.094 27.248.978 4.7043 1.328 9.595 2.376 14.2527z"
              />
              <g style={{ mixBlendMode: 'multiply' }}>
                <path fill="#FA92AE" d="M369.992 60.5706l-4.495 17.4666h9.316l-4.821-17.4666z" />
              </g>
              <path
                fill="black"
                fillOpacity=".7"
                d="M358.51 67.557c0 1.9447-.778 3.4934-1.747 3.4934-.969 0-1.746-1.572-1.746-3.4934 0-1.9213.787-3.4933 1.746-3.4933s1.747 1.5604 1.747 3.4933zM384.128 67.557c0 1.9447-.787 3.4934-1.747 3.4934-.959 0-1.746-1.572-1.746-3.4934 0-1.9213.777-3.4933 1.746-3.4933.97 0 1.747 1.5604 1.747 3.4933z"
              />
              <path
                fill="black"
                fillOpacity=".3"
                d="M370.154 90.845c-1.597.0123-3.152-.4311-4.413-1.2583-1.261-.8273-2.155-1.9899-2.537-3.2998-.039-.129-.047-.2631-.025-.3948.023-.1317.076-.2584.157-.3728.08-.1144.186-.2142.313-.2939.126-.0796.269-.1375.422-.1702.153-.0328.312-.0399.467-.0208.156.0191.306.0639.442.1319.135.068.253.1579.348.2645.094.1067.162.2279.201.3569.267.8648.865 1.6305 1.702 2.177s1.865.843 2.923.843c1.057 0 2.085-.2965 2.922-.843.837-.5465 1.436-1.3122 1.702-2.177.079-.2605.276-.484.55-.6214.273-.1374.6-.1774.908-.1111.309.0662.573.2331.736.4641.162.231.21.5071.131.7676-.367 1.3166-1.256 2.4871-2.521 3.3166-1.265.8294-2.828 1.2676-4.428 1.2415zM349.532 54.3211c1.414-1.4044 3.131-2.3936 4.99-2.8749 1.86-.4813 3.8-.4389 5.641.1232.381.082.777.0034 1.109-.2201.331-.2235.572-.5753.676-.9852.104-.4098.062-.8473-.117-1.225-.178-.3777-.481-.6677-.847-.8119-2.365-.7092-4.855-.752-7.239-.1244-2.384.6277-4.583 1.9053-6.392 3.7136-1.467 1.4476.712 3.8164 2.19 2.3927l-.011.012zM389.61 54.321c-1.416-1.4046-3.136-2.394-4.998-2.8754-1.862-.4813-3.806-.4389-5.649.1233-.38.0751-.773-.008-1.1-.2331-.327-.225-.565-.5756-.667-.9827-.102-.407-.06-.841.117-1.2162s.476-.6645.839-.8108c2.366-.7083 4.858-.7506 7.244-.1229 2.385.6278 4.586 1.9051 6.396 3.7127 1.469 1.4479-.713 3.8171-2.193 2.3932l.011.0119z"
              />
              <path
                fill="#FA92AE"
                d="M398.754 58.0204c1.618-1.9795 3.819-3.9358 6.369-3.6796.952.15 1.85.5412 2.608 1.1363.759.5952 1.352 1.3746 1.724 2.2639.72 1.7902 1.007 3.7252.838 5.6475.064 2.1005-.603 4.1582-1.886 5.8222-.701.6903-1.536 1.2294-2.453 1.5839-.917.3545-1.898.517-2.88.4772-1.959-.0932-3.895-.4576-5.753-1.0829l1.433-12.1685z"
              />
              <path
                fill="black"
                fillOpacity=".1"
                d="M405.284 63.8306c-.186 1.6651-1.083 2.9344-2.002 2.8296-.92-.1048-1.514-1.5371-1.328-3.2022.186-1.6652 1.083-2.9228 2.003-2.818.92.1048 1.514 1.5254 1.327 3.1906z"
              />
              <path
                fill="#FA92AE"
                d="M342.115 58.0204c-1.618-1.9795-3.819-3.9358-6.369-3.6796-.953.15-1.85.5412-2.609 1.1363-.758.5952-1.351 1.3746-1.723 2.2639-.72 1.7902-1.008 3.7252-.838 5.6475-.064 2.1005.603 4.1582 1.886 5.8222.699.6894 1.532 1.228 2.447 1.5825.915.3546 1.894.5174 2.874.4786 1.982-.0878 3.942-.4523 5.823-1.0829l-1.491-12.1685z"
              />
              <path
                fill="black"
                fillOpacity=".1"
                d="M335.582 63.8306c.186 1.6651 1.083 2.9344 2.003 2.8296.92-.1048 1.514-1.5371 1.327-3.2022-.186-1.6652-1.083-2.9228-2.003-2.818-.919.1048-1.513 1.5254-1.327 3.1906z"
              />
              <path
                fill="#313C6F"
                d="M371.085 40.775c-.309-.4349-.472-.9568-.465-1.4905 1.842.8422 3.808 1.3847 5.822 1.6069-.07-1.1644-.152-2.4569-.221-3.6913 1.849 1.5686 4.213 2.3981 6.637 2.3289.221-1.8631.431-3.7145.64-5.566 4.553 0 9.77 1.5371 11.54 5.729.528 1.4536.815 2.9836.85 4.5297.52 6.8004 1.048 13.5968 1.584 20.3894 3.176-3.8552 5.526-8.322 6.905-13.1233.806-3.0267 1.359-6.1154 1.653-9.234.815-7.6504.606-16.1624-4.448-21.9613-3.656-4.192-9.164-6.1599-14.439-7.895-10.573-3.49329-22.334-6.63727-32.534-2.1891-4.588 1.9912-8.419 5.3797-12.169 8.71l-6.672 5.9503c.448 1.278.424 2.6745-.07 3.9358-.524 1.933-1.036 3.8776-1.549 5.8222-.85 3.2139-1.7 6.5442-1.071 9.8046.459 1.8872 1.159 3.7071 2.084 5.4147.638 1.5884 1.417 3.1161 2.329 4.5646.932 1.3274 1.537 2.8529 2.422 4.2036.4.7037.947 1.3126 1.605 1.7849.657.4724 1.409.7969 2.203.9516-.933-3.0271-1.504-6.1541-1.7-9.3156-.466-7.464 2.946-9.4785 7.58-14.2178 2.071-2.3462 4.288-4.5592 6.638-6.6257 4.599 4.4831 7.161 9.5135 14.846 9.5834z"
              />
              <path
                fill="#FFF67F"
                d="M370.143 126.163l-6.183 4.996-.955 3.272 4.122 15.522 5.17.105 4.961-15.627-1.479-3.901-5.636-4.367z"
              />
              <path
                fill="white"
                d="M370.062 126.163l-7.313 20.96-13.007-15.137 6.498-15.907 13.822 10.084zM370.143 126.163l7.312 20.96 13.007-15.137-6.509-15.907-13.81 10.084z"
              />
              <path fill="#FFF67F" d="M367.126 149.953l-10.946 80.044h18.631l-2.515-79.939-5.17-.105z" />
              <path
                fill="white"
                d="M359.091 234.072c-7.767-22.52-5.612-111.309-5.612-111.309-3.6 3.916-7.663 7.381-12.099 10.317l-1.339 30.159 7.569 2.329-7.86 8.442s2.084 35.609 13.729 60.062h5.612zM373.064 231.743s10.446-59.386 14.324-102.47l.105-4.658c3.586 2.771 7.149 5.391 10.48 7.522 0 0 2.002 23.219 1.711 29.623l-6.113 3.203 6.113 7.277s-15.848 46.986-23.126 59.503h-3.494z"
                opacity=".05"
              />
              <path fill="#DDEDFA" d="M0 236h741v71H0z" />
              <path
                fill="#4455A1"
                d="M205.816 154.483l-18.794 6.556c-5.624 1.967-11.761 4.366-14.451 9.664-1.513 2.97-1.688 6.416-1.828 9.747-.869 20.261-.374 33.281-1.243 53.55 6.672 3.726 15.385 1.953 23 2.5 21.053 1.553 42.877.798 64 1.17 4.914.094 6.054 3.948 9 0 1.619-2.165 4.582 2.69 4.896 0 1.991-16.942 3.144-34.001 3.493-51.06.222-12.728-.07-25.746-4.471-37.693-.291-1.201-1.028-2.246-2.061-2.923-.953-.36-1.991-.425-2.981-.186l-24.838 3.493-33.722 5.182z"
              />
              <path stroke="#939EF9" strokeMiterlimit="10" d="M225.404 171.81L223.5 238.5" />
              <path
                fill="#FA92AE"
                d="M273.25 101.547c.295-.344.672-.61 1.097-.771.424-.162.882-.216 1.332-.155.45.06.878.231 1.245.499.367.268.661.623.855 1.033.582 1.218.839 2.566.746 3.912-.032 3.112-.832 6.168-2.329 8.897-.234.48-.568.904-.978 1.246-.526.305-1.129.451-1.735.419-.377.065-.764.042-1.13-.07-.366-.111-.701-.306-.978-.571l1.875-14.439z"
              />
              <path
                fill="#4455A1"
                d="M279.235 147.252c2.003 3.644 2.387 7.953 2.678 12.11 1.568 22.568 1.634 45.216.198 67.794-9.175-3.676-19.101-5.082-28.936-4.099v-66.129c0-3.167 0-6.509 1.595-9.315 4.541-8.035 20.028-8.466 24.465-.361z"
              />
              <path
                fill="#FA92AE"
                d="M226.439 171.192c3.214-4.296 5.299-5.251 8.594-7.347.673-.439 1.226-1.04 1.607-1.747.326-.664 2.073-.722 2.038-1.456-.355-5.029.038-10.083 1.164-14.998l-26.887-11.714 1.502 24.325c-.006.671.121 1.335.373 1.957.248.454.562.87.931 1.234l8.652 9.397.955.943 1.071-.594z"
              />
              <path
                fill="#6977B4"
                d="M225.403 171.809l-9.479-9.152-8.151 14.358-3.109-20.576 8.535-12.029 12.204 27.399z"
              />
              <path
                fill="#6977B4"
                d="M225.402 171.81l13.415-19.633 1.56.187 6.591.395 2.329 15.429-9.607-6.695-14.288 10.317z"
              />
              <path
                fill="#FA92AE"
                d="M216.017 137.296c5.636 6.02 14.054 8.675 22.252 9.408 7.697.699 15.941-.244 22.124-4.855 7.313-5.45 10.376-14.847 12.239-23.767 2.27-10.899 3.353-22.3221.477-33.0698-2.876-10.7478-10.305-20.7504-20.82-24.4533-12.366-4.2968-26.782 1.1644-35.178 11.2718-13.181 15.8247-16.034 49.5233-1.094 65.4653z"
              />
              <path
                fill="black"
                d="M214.119 139.974c.629 4.797 4.076 8.896 8.303 11.248 4.33 2.197 9.117 3.342 13.973 3.342 1.908.002 3.814.126 5.706.373 1.094.174 2.177.465 3.283.64 2.774.306 5.578.156 8.303-.442 2.147-.265 4.209-.996 6.043-2.143.909-.592 1.625-1.436 2.061-2.429.437-.992.574-2.091.396-3.16l-48.068-7.429z"
                opacity=".2"
              />
              <path
                fill="#313C6F"
                d="M222.166 87.3526c.291-.3261.594-.6521.908-.9665 1.94-1.9587 4.308-3.4413 6.917-4.3317.151 0-1.421-1.607-1.572-1.6885-6.125-3.668-20.96-9.6066-25.839-.9781-1.273 2.67-1.461 5.7297-.524 8.5354.757 2.8296 2.084 5.4728 2.911 8.2791 1.164 3.9327 1.352 8.0887.547 12.1107-.507 1.866-.862 3.77-1.059 5.694.053 1.021.333 2.017.821 2.915.487.899 1.17 1.677 1.997 2.278.279-3.924-.105-8.058.908-11.877.734-2.76.582-5.892 1.491-8.5705.663-1.9447 2.98-4.0989 4.657-5.2284 1.398-.9548 2.993-1.5603 4.355-2.5501 1.284-1.0828 2.45-2.2965 3.482-3.6214z"
              />
              <path
                fill="#FA92AE"
                d="M210.626 95.5852c-.603-.6923-1.34-1.2555-2.167-1.6557-.826-.4002-1.725-.6292-2.642-.6732-.932-.0287-1.857.1593-2.704.5493-.847.39-1.591.9713-2.175 1.698-1.328 1.6768-1.584 3.9475-1.712 6.0904-.163 2.526-.081 5.461 1.887 7.056 1.483.967 3.24 1.425 5.007 1.304 1.607 0 3.493 0 4.46-1.269l.046-13.0998z"
              />
              <path
                fill="black"
                d="M204.956 105.215c.803 0 1.455-1.522 1.455-3.4 0-1.8781-.652-3.4004-1.455-3.4004-.804 0-1.456 1.5223-1.456 3.4004 0 1.878.652 3.4 1.456 3.4z"
                opacity=".1"
              />
              <path
                fill="#313C6F"
                d="M275.043 127.409c-.447 5.074-1.705 10.043-3.726 14.719-.489 1.15-1.14 2.225-1.933 3.191-1.13 1.177-2.434 2.174-3.866 2.957-10.48 6.428-25.233 4.437-36.866 2.224-2.051-.338-4.058-.9-5.985-1.677-7.604-3.295-11.645-12.727-14.579-19.923-.591-1.333-1.017-2.733-1.269-4.169-.594-3.784.675-7.58 1.572-11.307 1.607-6.625 1.059-13.274 2.154-19.8882l4.075-.8733c-.151 0 .513 14.6715.443 16.0575-.315 5.822.175 12.401 4.052 17.071.349.52.818.949 1.367 1.251.549.302 1.162.468 1.789.484.624-.099 1.217-.338 1.735-.699 4.262-2.538 6.229-4.332 10.491-6.87 7.964-4.716 19.818-5.287 26.2 1.747 2.694 3.227 4.967 6.256 6.708 10.084 3.493-6.673 5.193-13.554 6.602-21.356.85 1.677 1.246 5.182 1.327 7.58.121 3.136.024 6.276-.291 9.397z"
              />
              <path
                fill="#6977B4"
                d="M251.812 217.957c-1.607-.979-1.549-3.494-.303-4.949 1.378-1.315 3.186-2.084 5.088-2.166 6.428-.804 12.926.792 19.004 3.027 4.553 1.654 9.432 4.192 10.841 8.827.606 1.979.116 4.658-1.851 5.286.858 1.778 1.39 3.694 1.572 5.66.081.983-.069 1.972-.436 2.888-.368.915-.945 1.733-1.684 2.387-.758.629-1.72.959-2.705.927-.985-.032-1.924-.424-2.64-1.102-.564-.74-.997-1.571-1.28-2.457-1.716-3.7-4.68-6.677-8.373-8.407-3.678-1.705-7.683-2.591-11.737-2.597-2.329-.058-5.741.547-7.15-1.712-.547-.896-.047-6.649 1.654-5.612z"
              />
              <g style={{ mixBlendMode: 'multiply' }}>
                <path fill="#FA92AE" d="M249.053 97.2502l-5.822 16.8848h9.897l-4.075-16.8848z" />
              </g>
              <path
                fill="black"
                fillOpacity=".7"
                d="M238.741 104.053c-.162 1.612-1.065 2.818-2.03 2.721-.964-.097-1.606-1.479-1.445-3.072.16-1.593 1.075-2.817 2.029-2.721.955.096 1.608 1.469 1.446 3.072zM260.847 104.968c-.163 1.612-1.076 2.817-2.03 2.721-.954-.096-1.607-1.479-1.446-3.072.161-1.593 1.066-2.818 2.03-2.721.964.097 1.607 1.469 1.446 3.072z"
              />
              <path
                fill="black"
                fillOpacity=".3"
                d="M230.57 98.7768c1.372-1.0912 3.003-1.8205 4.74-2.1194 1.737-.299 3.523-.1578 5.191.4101.348.089.717.0452 1.033-.1226.316-.1679.556-.4474.672-.7827.116-.3352.099-.7015-.047-1.0254-.147-.324-.411-.5817-.742-.7216-2.143-.719-4.436-.8904-6.664-.4983-2.227.3921-4.316 1.335-6.073 2.7406-1.423 1.1242.468 3.2136 1.9 2.1099l-.01.0094zM266.544 100.906c-1.127-1.2395-2.531-2.1485-4.08-2.642-1.55-.4936-3.194-.5555-4.778-.1799-.325.0435-.652-.0453-.918-.2489-.265-.2035-.449-.5071-.515-.8508-.065-.3437-.009-.7026.159-1.0059s.435-.529.748-.6325c2.034-.471 4.141-.3816 6.125.2599 1.984.6414 3.78 1.814 5.22 3.4082 1.17 1.2779-.79 3.1399-1.97 1.8809l.009.011z"
              />
              <path
                fill="#313C6F"
                d="M273.692 96.8194c.623 4.7036.931 8.9776.308 13.6806 1.036-1.828.414-3.62 1.02-5.634.792-2.632 3.109-4.658 3.819-7.278.262-1.1882.356-2.4072.28-3.6214.018-.2189-.018-.439-.105-.6405-.135-.2092-.324-.3782-.547-.489-1.413-.8205-2.971-1.3584-4.588-1.5837l-.187 5.566z"
              />
              <path
                fill="black"
                d="M284.278 91.6031L201.94 80.634c24.349 11.4232 48.802 18.0489 75.689 19.667l.419-.7331c.24-.4218.447-.8617.617-1.3159.07-.4192 5.613-6.6489 5.613-6.6489z"
                opacity=".2"
              />
              <path
                fill="#F7C92B"
                d="M200.391 80.75s3.889-45.4132 55.124-37.6464c0 0 34.153 9.6998 29.496 50.8512L200.391 80.75z"
              />
              <path
                fill="#F2B51D"
                d="M200.391 80.7502s105.463 25.4428 95.88 19.027c-3.496-2.4037-7.278-4.3597-11.26-5.8222l-84.62-13.2048z"
              />
              <path
                fill="#DEAA30"
                d="M267.347 91.9174l-1.223-.9316s17.164-33.7688-26.118-48.627l.14-1.1645 9.595 1.5953 11.714 5.8921 4.145 5.1002 5.578 6.7887 3.493 8.0813-.209 4.9488-.222 7.4175-6.893 10.8992z"
              />
              <path
                fill="#F7E249"
                d="M240.145 41.1943s44.376 9.0943 27.201 50.7231l9.607 1.4555s17.466-38.7176-25.618-51.5265l-11.19-.6521z"
              />
              <path
                fill="#FA92AE"
                d="M212.955 228.25c-8.85 1.165-17.862 2.236-26.631.606-5.705-1.06-11.341-3.272-17.129-2.795-3.971.413-7.713 2.059-10.702 4.707-2.988 2.648-5.073 6.165-5.961 10.058 4.262 2.783 8.629-4.029 13.647-4.902 5.974-1.025 10.096 6.532 16.07 7.545 3.19.548 6.346-.873 9.455-1.77 7.581-2.212 15.662-1.409 23.533-.757 18.107 1.503 36.296 2.12 54.461 1.852 3.494 0 7.325-.279 9.828-2.702 4.076-3.959 1.77-11.306-2.759-14.741-8.082-6.149-18.038-3.936-27.004-1.98-11.994 2.632-24.663 3.342-36.808 4.879z"
              />
              <path fill="white" d="M200.461 199.453l-.733-9.443 11.772-1.526.151 10.317-11.19.652z" />
              <path fill="#D65656" d="M196.804 186.062l-.419-3.365.78-.093.512 3.179-.873.279z" />
              <path fill="#1D262D" d="M199.948 199.244l-1.956-14.032-1.747.361 1.595 14.172 2.108-.501z" />
              <path
                fill="#D65656"
                d="M195.967 186.807l2.457-.605.326 2.41-2.643.373.233 3.225.326-.093v.699h-.699l-.594-5.915.594-.094z"
              />
              <path
                fill="#939EF9"
                d="M195.814 198.941l16.617-2.049.233 3.412-16.628 1.828-.222-3.191zM229.397 186.703c.002-.271-.076-.536-.225-.761-.149-.226-.362-.403-.611-.507-.249-.105-.524-.133-.79-.081-.265.053-.509.183-.7.374-.191.191-.321.435-.374.7-.052.266-.024.541.081.79.104.249.281.462.507.611.225.149.49.227.761.225.358 0 .702-.143.955-.396s.396-.597.396-.955zM229.397 176.817c-.001-.272-.082-.538-.235-.763-.152-.225-.368-.399-.621-.501-.252-.101-.529-.124-.795-.067-.265.058-.508.193-.696.39-.188.196-.313.444-.359.712-.045.268-.01.544.102.792.112.247.296.456.527.598.232.143.501.213.772.201.353-.012.686-.161.93-.416.243-.254.378-.594.375-.946zM229.397 196.181c.002-.27-.076-.535-.225-.761-.149-.226-.362-.402-.611-.507-.249-.104-.524-.132-.79-.08-.265.052-.509.182-.7.373-.191.192-.321.435-.374.701-.052.265-.024.54.081.789.104.25.281.463.507.611.225.149.49.227.761.225.358 0 .702-.142.955-.396.253-.253.396-.596.396-.955zM229.397 205.648c.002-.27-.076-.535-.225-.761-.149-.226-.362-.402-.611-.507-.249-.104-.524-.132-.79-.08-.265.052-.509.182-.7.373-.191.192-.321.436-.374.701-.052.265-.024.54.081.79.104.249.281.462.507.61.225.149.49.227.761.225.358 0 .702-.142.955-.395.253-.254.396-.597.396-.956zM229.397 215.115c.002-.27-.076-.535-.225-.761-.149-.226-.362-.403-.611-.507-.249-.105-.524-.133-.79-.08-.265.052-.509.182-.7.373-.191.191-.321.435-.374.7-.052.266-.024.541.081.79.104.25.281.462.507.611.225.149.49.227.761.225.358 0 .702-.143.955-.396s.396-.597.396-.955z"
              />
              <path
                fill="white"
                fillOpacity=".2"
                d="M247.297 126.08c-1.196-.061-2.34-.484-3.245-1.201-.905-.716-1.519-1.684-1.743-2.747-.023-.105-.023-.213 0-.317.023-.104.069-.203.135-.29.065-.088.15-.163.248-.221.098-.058.208-.098.324-.118.116-.019.235-.018.351.005.116.022.226.064.324.124.098.061.183.138.248.227.066.089.111.189.134.294.159.703.571 1.341 1.172 1.814.6.474 1.356.756 2.148.803.792.047 1.575-.145 2.228-.544.652-.399 1.137-.984 1.377-1.663.071-.205.229-.374.441-.472.211-.098.457-.115.685-.048.228.066.418.211.529.403.111.191.133.414.062.619-.337 1.035-1.058 1.93-2.044 2.537-.986.606-2.177.887-3.374.795z"
              />
              <path
                fill="#F3F3F3"
                d="M312.002 164.695h107.687c.853 0 1.67.338 2.273.941.603.603.941 1.42.941 2.272v68.4H308.788v-68.4c0-.852.339-1.669.941-2.272.603-.603 1.421-.941 2.273-.941z"
              />
              <path
                fill="#E5E5E5"
                d="M308.788 236.529h114.115v3.691c0 .578-.229 1.131-.637 1.54-.409.408-.963.638-1.54.638H310.977c-.577 0-1.131-.23-1.539-.638-.409-.409-.638-.962-.638-1.54v-3.691h-.012zM365.845 208.943c3.859 0 6.987-3.128 6.987-6.986 0-3.859-3.128-6.987-6.987-6.987s-6.987 3.128-6.987 6.987c0 3.858 3.128 6.986 6.987 6.986z"
              />
            </svg>
          </g>
        )}
      </svg>
    </Waypoint>
  );
}

export default InfoTechnology;
