import React from 'react';

import CloseButton from '../Buttons/CloseButton';
import { StoreContext } from '../../store';
import poster from '../../images/VideoPoster.jpg';
import videoFile from '../../images/Video.mp4';

import Modal from './Modal';
import style from './VideoPopup.module.css';

function VideoPopup(): JSX.Element {
  const { video } = React.useContext(StoreContext);

  return (
    <Modal contact>
      <CloseButton close={video.close} />
      <video controls poster={poster} className={style.video}>
        <source src={videoFile} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
      </video>
    </Modal>
  );
}

export default VideoPopup;
