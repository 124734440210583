import React from 'react';
import cn from 'classnames';

import style from './InfoItem.module.css';

type Item = {
  id: number;
  text: string;
};

type Props = {
  header: string;
  items: Item[];
  isTwoColumn?: boolean;
  additionalInfo?: string;
  className?: string | string[];
  children?: React.ReactNode;
};

function InfoItem({ header, items, isTwoColumn, additionalInfo, className, children }: Props): JSX.Element {
  return (
    <section className={cn(style.item, className)}>
      {children}
      <div className={cn(style.text)}>
        <h3 className={style.header}>{header}</h3>
        <ul className={cn(style.list, isTwoColumn && style.twoColumn)}>
          {items.map((item: Item) => (
            <li key={item.id}>{item.text}</li>
          ))}
        </ul>
        {additionalInfo && <p className={style.additional}>{additionalInfo}</p>}
      </div>
    </section>
  );
}

export default InfoItem;
