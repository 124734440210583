import React from 'react';
import { Formik, Field, Form } from 'formik';
import cn from 'classnames';

import { StoreContext } from '../../store';
import Input from '../Inputs/Input';
import Dropdown from '../Inputs/Dropdown';
import SubmitButton from '../Buttons/SubmitButton';

import Schema from './FooterFormSchema';
import style from './FooterForm.module.css';

type Item = {
  value: string;
  id: number;
};

type MyFormValues = {
  name: string;
  email: string;
  companyName: string;
  equipment: string;
  companyType: Item | null;
};

type Props = {
  className?: string;
};

const companyTypeItems = [
  { value: 'Нефтяная / Газовая', id: 1 },
  { value: 'Буровая / Сервисная', id: 2 },
  { value: 'Производитель оборудования', id: 3 },
  { value: 'Другая', id: 4 },
];

type T = Window & typeof globalThis;
interface MyWindow extends T {
  ['Infinity']: any;
  ['NaN']: any;
  env: {
    REACT_APP_API_URL: string;
  };
}

function FooterForm({ className }: Props): JSX.Element {
  const { success, failed, preloader } = React.useContext(StoreContext);
  // const handleSubmit = (values: MyFormValues): void => {
  // };

  async function sendMail(values: any) {
    try {
      preloader.open();
      await fetch(`${(window as MyWindow).env.REACT_APP_API_URL}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      preloader.close();
      success.open();
    } catch (error) {
      preloader.close();
      failed.open();
    }
  }

  const handleSubmit = (values: MyFormValues, actions: any): void => {
    const postValues = {
      name: values.name,
      company: values.companyName,
      email: values.email,
      ['company_type']: values.companyType ? values.companyType.value : '',
      equipment: values.equipment,
    };
    sendMail(postValues);
    actions.resetForm({
      name: '',
      email: '',
      companyName: '',
      equipment: '',
      companyType: null,
    });
  };

  function renderForm(): JSX.Element {
    return (
      <Form className={style.form}>
        <Field type="text" name="name" component={Input} placeholder={'Как Вас зовут?'} small />
        <Field type="text" name="email" component={Input} placeholder={'Ваш e-mail'} small />
        <Field type="text" name="companyName" component={Input} placeholder={'Название Вашей компании'} small />
        <Field type="text" name="equipment" component={Input} placeholder={'Ваше оборудование'} small />
        <Field name="companyType" component={Dropdown} placeholder={'Тип Вашей компании'} items={companyTypeItems} />
        <SubmitButton title="Отправить" className={style.button} small />
      </Form>
    );
  }

  return (
    <div className={cn(style.container, className)}>
      <h3 className={style.header}>
        Как мы можем помочь Вам?
        <br />
        Свяжитесь с нами в любое время
      </h3>
      <div className={style.square} />
      <Formik
        initialValues={{
          name: '',
          email: '',
          companyName: '',
          equipment: '',
          companyType: null,
        }}
        validationSchema={Schema}
        onSubmit={handleSubmit}
        render={renderForm}
      />
    </div>
  );
}

export default FooterForm;
