import React, { useState } from 'react';
import cn from 'classnames';
import { FieldProps } from 'formik';

import style from './Checkbox.module.css';

type Props = {
  field: FieldProps['field'];
  form: FieldProps['form'];
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.SyntheticEvent) => void;
};

function Checkbox({ form, field, handleChange, handleBlur }: Props): JSX.Element {
  const [isChecked, setChecked] = useState(false);

  const touched = form ? form.touched : {};
  const errors = form ? form.errors : {};
  const name = field ? field.name : '';
  const isError = touched[name] && errors[name];

  function change(e: React.ChangeEvent<HTMLInputElement>): void {
    handleChange(e);
    setChecked(!isChecked);
  }

  return (
    <label className={cn(style.container, isChecked && style.blackLabel, isError && style.errorLabel)} htmlFor="agree">
      Я согласен(-на) на обработку персональных данных
      <input type="checkbox" name="agree" onChange={change} onBlur={handleBlur} id="agree" />
      <span className={cn(style.checkmark, isError && style.errorCheckmark)} />
    </label>
  );
}

export default Checkbox;
