import React from 'react';
import { Formik, Field, Form, FormikProps } from 'formik';

import Input from '../Inputs/Input';
import PhoneDropdown from '../Inputs/PhoneInput';
import SubmitButton from '../Buttons/SubmitButton';

import Schema from './SignUpStepTwoSchema';
import style from './SignUpStepTwo.module.css';

type FormValues = {
  companyName: string;
  address: string;
  phone: string;
  size: string;
};

type Props = {
  handleContinue: () => void;
};

function SignUpStepOne({ handleContinue }: Props): JSX.Element {
  const handleSubmit = (values: FormValues): void => {
    handleContinue();
  };

  function renderForm(props: FormikProps<any>): JSX.Element {
    return (
      <Form className={style.form}>
        <Field type="text" name="companyName" component={Input} placeholder={'Название'} icon="card" />
        <Field type="text" name="address" component={Input} placeholder={'Адрес'} icon="address" />
        <Field
          type="text"
          name="phone"
          component={PhoneDropdown}
          placeholder={'Телефон'}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.phone}
        />
        <Field type="number" name="size" component={Input} placeholder={'Размер компании'} icon="card" />
        <SubmitButton title="Продолжить" className={style.button} />
      </Form>
    );
  }

  return (
    <>
      <h2 className={style.title}>Расскажите о Вашей компании</h2>
      <Formik
        initialValues={{
          companyName: '',
          address: '',
          phone: '',
          size: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={Schema}
        render={renderForm}
      />
    </>
  );
}

export default SignUpStepOne;
