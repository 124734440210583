import React from 'react';
import cn from 'classnames';

import { AdvantageGroup } from './types';
import style from './AdvantagesMenu.module.css';

type Props = {
  advantages: AdvantageGroup[];
  active: number;
  setActive: (index: number) => void;
  className?: string;
};

function AdvantagesMenu({ advantages, active, setActive, className }: Props): JSX.Element {
  return (
    <ul className={className}>
      {advantages.map((item: AdvantageGroup, index: number) => {
        const handleSetActive = (): void => {
          setActive(index);
        };
        return (
          <li className={cn(style.item, index === active && style.active)} key={item.id} onClick={handleSetActive}>
            {item.title}
          </li>
        );
      })}
    </ul>
  );
}

export default AdvantagesMenu;
