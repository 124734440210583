import * as Yup from 'yup';

const SignUpStepTwoSchema = Yup.object().shape({
  companyName: Yup.string().required('This field is required'),
  address: Yup.string().required('This field is required'),
  phone: Yup.string().min(15, 'Phone number is not valid'),
  size: Yup.string().required('This field is required'),
});

export default SignUpStepTwoSchema;
