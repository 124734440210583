import React from 'react';
import { Formik, Field, Form, FormikProps } from 'formik';

import Input from '../Inputs/Input';
import PasswordInput from '../Inputs/PasswordInput';
import Checkbox from '../Buttons/Checkbox';
import SubmitButton from '../Buttons/SubmitButton';

import Schema from './SignUpStepOneSchema';
import style from './SignUpStepOne.module.css';

type MyFormValues = {
  name: string;
  surname: string;
  country: string;
  email: string;
  password: string;
  confirmPassword: string;
  agree: boolean;
};

type Props = {
  handleContinue: () => void;
  openSignIn: () => void;
};

function SignUpStepOne({ handleContinue, openSignIn }: Props): JSX.Element {
  const handleSubmit = (values: MyFormValues): void => {
    if (values.password && values.password === values.confirmPassword && values.agree) {
      handleContinue();
    }
  };

  function renderForm(props: FormikProps<any>): JSX.Element {
    return (
      <Form className={style.form}>
        <Field type="text" name="name" component={Input} placeholder={'Имя'} icon="man" />
        <Field type="text" name="surname" component={Input} placeholder={'Фамилия'} icon="man" />
        <Field type="text" name="country" component={Input} placeholder={'Страна'} icon="address" />
        <Field type="text" name="email" component={Input} placeholder={'Email'} icon="email" />
        <Field type="password" name="password" component={PasswordInput} placeholder={'Пароль'} />
        <Field type="password" name="confirmPassword" component={PasswordInput} placeholder={'Повторите пароль'} />
        <Field type="checkbox" name="agree" component={Checkbox} handleChange={props.handleChange} />
        <SubmitButton title="Продолжить" className={style.button} />
        <p className={style.textRedirect}>
          У Вас уже есть аккаунт?
          <button type="button" onClick={openSignIn}>
            Войдите
          </button>
        </p>
      </Form>
    );
  }

  return (
    <>
      <h2 className={style.title}>Добро пожаловать!</h2>
      <p className={style.description}>Зарегистрируйтесь, и самая лучшая в мире система будет всегда под рукой</p>
      <Formik
        initialValues={{
          name: '',
          surname: '',
          country: '',
          email: '',
          password: '',
          confirmPassword: '',
          agree: false,
        }}
        validationSchema={Schema}
        onSubmit={handleSubmit}
        render={renderForm}
      />
    </>
  );
}

export default SignUpStepOne;
