import React from 'react';

import CloseButton from '../Buttons/CloseButton';
import SubmitButton from '../Buttons/SubmitButton';
import { StoreContext } from '../../store';

import Modal from './Modal';
import style from './SuccessPopup.module.css';

function SuccessPopup(): JSX.Element {
  const { success } = React.useContext(StoreContext);
  return (
    <Modal contact className={style.modal}>
      <CloseButton close={success.close} />
      <h2 className={style.title}>Ваш ответ был успешно отправлен!</h2>
      <SubmitButton title="Спасибо" className={style.button} onClick={success.close} />
    </Modal>
  );
}

export default SuccessPopup;
