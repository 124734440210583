import React from 'react';

import style from './ResetPassword.module.css';

type Props = {
  email: string;
  openForgot: () => void;
};

function ResetPassword({ email, openForgot }: Props): JSX.Element {
  return (
    <>
      <h2 className={style.title}>Восстановление пароля</h2>
      <p className={style.description}>
        Мы отправили письмо с паролем сброса на адрес <span className={style.email}>{email}</span>. Пожалуйста, нажмите
        на ссылку для сброса пароля, чтобы создать новый пароль.
      </p>
      <p className={style.secondDescription}>
        Вы еще не получили письмо? Пожалуйста, проверьте папку со спамом или
        <button type="button" className={style.button} onClick={openForgot}>
          отправьте письмо повторно
        </button>
        .
      </p>
    </>
  );
}

export default ResetPassword;
