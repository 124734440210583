import React, { useState } from 'react';

import image from '../../images/CalculatorBackground.png';

import style from './Calculator.module.css';

function Calculator(): JSX.Element {
  const maxValue = 21;

  const [value, setValue] = useState<number>(5);

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(parseInt(ev.target.value, 10));
  };

  function calculatePrice(): number {
    const bet = 349;
    const ratio = (100 - value) / 100;
    if (value === 1) return bet;
    return Math.round(value * bet * ratio);
  }

  const thumbStyle = {
    left: `calc(${((value - 2) / maxValue) * 100}%)`,
  };

  const isMax = value === 21;

  return (
    <article className={style.container} id="calculator">
      <h2 className={style.header}>Рассчитайте стоимость ежемесячного платежа</h2>

      <p className={`${style.price} ${isMax && style.priceName}`}>
        {isMax && 'Договорная цена'}
        {isMax ||
          `$${calculatePrice()
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`}
      </p>

      <div className={style.sliderContainer}>
        <div className={style.range} />
        <input
          type="range"
          min="1"
          max={maxValue}
          value={value}
          className={style.slider}
          onChange={handleChange}
          id="range"
        />
        <span className={`${style.value} ${isMax && style.valueMax}`} style={thumbStyle} id="value">
          {isMax || value}
          {isMax && (
            <span>
              20<sup>+</sup>
            </span>
          )}
        </span>
        {/* <span className={style.valueMax} style={thumbStyle} id="value">
        </span> */}
      </div>
      <p className={style.text}>Сколько скважин в месяц Вы бурите?</p>
      <img className={style.image} src={image} title="Казахстан" alt="Пейзаж Казахстана" />
      <div className={style.square} />
      {/* <div className={style.shadow} /> */}
    </article>
  );
}

export default Calculator;
