import React from 'react';

import { MainAnimation } from '../SVG';
import { StoreContext } from '../../store';

import style from './Main.module.css';

function Main(): JSX.Element {
  const { contactUs, video } = React.useContext(StoreContext);
  return (
    <article className={style.container}>
      <h1>Удаленный мониторинг бурения скважин&nbsp;в реальном времени</h1>
      <p>Геологический, технологический и производственный контроль бурения с оплатой только за активные скважины</p>
      <div className={style.pattern} />
      <div className={style.buttons}>
        <button className={style.try} onClick={contactUs.open}>
          Попробовать бесплатно
        </button>
        <button className={style.look} onClick={video.open}>
          Как это выглядит?
        </button>
      </div>
      <div className={style.grayRect}>
        <MainAnimation className={style.animation} />
      </div>
    </article>
  );
}

export default Main;
