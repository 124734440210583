import React from 'react';
import cn from 'classnames';
import PhoneInput from 'react-phone-input-2';
import { FieldProps } from 'formik';

import './style.css';
import ru from './ru.json';
import style from './PhoneInput.module.css';

type Props = {
  field: FieldProps['field'];
  form: FieldProps['form'];
  className?: string;
  placeholder?: string;
  props: object;
};

const PhoneDropdown = ({ field, form, className, placeholder, ...props }: Props): JSX.Element => {
  const touched = form ? form.touched : {};
  const errors = form ? form.errors : {};
  const { name } = field;
  const isError = touched[name] && errors[name];

  const { setFieldValue } = form;

  function handleChange(event: MouseEvent): void {
    setFieldValue('phone', event, false);
  }

  return (
    <div className={style.wrapper}>
      <div className={cn(style.container, className, isError && style.incorrectInput)}>
        <PhoneInput
          localization={ru}
          searchPlaceholder={'Поиск'}
          {...props}
          {...field}
          placeholder={placeholder}
          enableSearchField={true}
          inputExtraProps={{
            name: 'phone',
          }}
          onChange={handleChange}
        />
      </div>
      {isError && (
        <div className={style.errorContainer}>
          <p>{isError}</p>
        </div>
      )}
    </div>
  );
};

export default PhoneDropdown;
