import React from 'react';
import cn from 'classnames';

import style from './CloseButton.module.css';

type Props = {
  className?: string;
  close: () => void;
};

function CloseButton({ className, close }: Props): JSX.Element {
  return <button className={cn(style.button, className)} onClick={close}></button>;
}

export default CloseButton;
