import React, { useState } from 'react';
import cn from 'classnames';

import arrowUp from '../../images/EquipmentArrowUp.svg';
import arrowDown from '../../images/EquipmentArrowDown.svg';
import { StoreContext } from '../../store';

import style from './Equipment.module.css';

type Station = {
  title: string;
  address: string;
};

type Equipment = {
  title: string;
  stations: Station[];
};

type Equipments = {
  equipment: Equipment[];
};

function Equipment({ equipment }: Equipments): JSX.Element {
  const { contactUs } = React.useContext(StoreContext);

  const [isOpen, setOpen] = useState(false);
  const [equipmentIndex, setEquipmentIndex] = useState(0);

  function handleOpen(index: number): void {
    if (equipmentIndex === index) {
      setOpen(!isOpen);
    } else {
      setOpen(true);
    }
    setEquipmentIndex(index);
  }

  return (
    <article className={style.container} id="equipments">
      <h2>Поддерживаемое оборудование</h2>
      <p>Подключим любое оборудование, которое отдает данные по WITS-стандарту.</p>
      {equipment.map((item, index) => {
        function handleOpenItem(): void {
          handleOpen(index);
        }
        return (
          <div className={style.equipmentItem} key={index}>
            <div className={style.equipmentItemHeader} onClick={handleOpenItem}>
              <h3 className={style.title}>{item.title}</h3>
              <img src={isOpen && equipmentIndex === index ? arrowUp : arrowDown} alt="arrow" />
            </div>
            <div
              className={cn(style.equipmentItemBody, isOpen && equipmentIndex === index && style.equipmentItemBodyOpen)}
            >
              {item.stations.map((station, index) => {
                return (
                  <div className={style.stationItem} key={index}>
                    <div>
                      <h4>{station.title}</h4>
                      <p>{station.address}</p>
                    </div>
                    {/* <button className={style.download} onClick={signIn.open}>
                      Скачать инструкцию
                    </button> */}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      <div className={style.contact}>
        <button onClick={contactUs.open}>Связаться с Innerica</button>
        <p>Не нашли своё оборудование? Напишите нам, мы и его подключим!</p>
      </div>
    </article>
  );
}

export default Equipment;
