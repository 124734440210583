import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { FieldProps } from 'formik';

import arrow from '../../images/DropdownArrow.svg';

import style from './Dropdown.module.css';

type Props = {
  field: FieldProps['field'];
  form: FieldProps['form'];
  placeholder?: string;
  items: Item[];
  className?: string;
  handleOptionOpen?: () => void;
  closeOption?: () => void;
};

type Item = {
  value: string;
  id: number;
};

const Dropdown = ({
  field,
  form,
  placeholder,
  items,
  className,
  handleOptionOpen,
  closeOption,
}: Props): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const isEmpty = field.value === '' || field.value === null;

  useEffect(() => {
    if (field.name === 'companyType' && !isEmpty) {
      if (handleOptionOpen) handleOptionOpen();
    }
    if (field.name === 'companyType' && field.value === 3) {
      if (closeOption) closeOption();
    }
  }, [field.name, field.value, isEmpty, handleOptionOpen, closeOption]);

  const touched = form ? form.touched : {};
  const errors = form ? form.errors : {};
  const { name } = field;
  const isError = touched[name] && errors[name];

  function handleSelect(item: Item): void {
    form.setFieldValue(field.name, item, false);
  }

  function getTitle(id: number): string | undefined {
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === id) {
        return items[i].value;
      }
    }
  }

  function handleOpen(): void {
    setOpen(!isOpen);
  }

  return (
    <div className={cn(style.wrapper, className)}>
      <div
        className={cn(style.container, isOpen && style.openContainer, isError && style.incorrectContainer)}
        onClick={handleOpen}
      >
        <input
          value={getTitle(field.value ? field.value.id : undefined)}
          placeholder={placeholder || 'Не выбрано'}
          className={cn(style.input, !isEmpty && style.filledInput)}
          type="text"
        />
        <img className={cn(style.arrow, isOpen && style.arrowUp)} src={arrow} alt="arrow" onClick={handleOpen} />
        {isOpen && (
          <ul className={style.list}>
            {items
              .filter(item => item.id !== field.value)
              .map((item, index) => {
                function selectItem(): void {
                  handleSelect(item);
                }
                return (
                  <li className={style.item} key={index} onClick={selectItem}>
                    {item.value}
                  </li>
                );
              })}
          </ul>
        )}
      </div>
      <div className={style.errorContainer}>
        <p>{isError}</p>
      </div>
    </div>
  );
};

export default Dropdown;
