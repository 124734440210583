import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';

import { COOKIE_CONSENT_NO } from './constants';

const DynamicScriptLoader = () => {
  const [cookies] = useCookies(['cookieConsent']);

  useEffect(() => {
    const loadScript = (src, callback) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = src;
      script.onload = callback;
      document.head.appendChild(script);
    };

    if (cookies['cookieConsent'] && cookies['cookieConsent'] !== COOKIE_CONSENT_NO) {
      loadScript('https://mc.yandex.ru/metrika/tag.js', () => {
        window.ym =
          window.ym ||
          function() {
            // eslint-disable-next-line prefer-rest-params
            (window.ym.a = window.ym.a || []).push(arguments);
          };
        window.ym.l = 1 * new Date();
        window.ym(56353219, 'init', {
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
        });
      });
    }
  }, [cookies]);

  return (
    <>
      {cookies['cookieConsent'] && cookies['cookieConsent'] !== COOKIE_CONSENT_NO && (
        <noscript>
          <div>
            <img src="https://mc.yandex.ru/watch/56353219" style={{ position: 'absolute', left: '-9999px' }} alt="" />
          </div>
        </noscript>
      )}
    </>
  );
};

export default DynamicScriptLoader;
