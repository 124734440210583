import React from 'react';
import cn from 'classnames';

import { Review } from './types';
import style from './ReviewScroll.module.css';

type Props = { current: number; reviews: Review[] };

function ReviewScroll({ current, reviews }: Props): JSX.Element {
  return (
    <div className={style.container}>
      {reviews.map((item, index) => (
        <div key={item.id} className={cn(style.item, index === current && style.current)} />
      ))}
    </div>
  );
}

export default ReviewScroll;
