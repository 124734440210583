import React, { useState } from 'react';

type Props = {
  children: React.ReactNode;
};

type Company = {
  type: number | null;
  setType: (type: number) => void;
};

type Popup = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

type ContextProps = {
  signUp: Popup;
  signIn: Popup;
  forgot: Popup;
  newPassword: Popup;
  contactUs: Popup;
  video: Popup;
  success: Popup;
  failed: Popup;
  preloader: Popup;
  companyType: Company;
};

export const StoreContext = React.createContext<ContextProps>({
  signUp: { isOpen: false, open: (): void => {}, close: (): void => {} },
  signIn: { isOpen: false, open: (): void => {}, close: (): void => {} },
  forgot: { isOpen: false, open: (): void => {}, close: (): void => {} },
  newPassword: { isOpen: false, open: (): void => {}, close: (): void => {} },
  contactUs: { isOpen: false, open: (): void => {}, close: (): void => {} },
  video: { isOpen: false, open: (): void => {}, close: (): void => {} },
  success: { isOpen: false, open: (): void => {}, close: (): void => {} },
  failed: { isOpen: false, open: (): void => {}, close: (): void => {} },
  preloader: { isOpen: false, open: (): void => {}, close: (): void => {} },
  companyType: { type: null, setType: (): void => {} },
});

function Store({ children }: Props): JSX.Element {
  const [isSignUpOpen, setSignUpOpen] = useState(false);
  const [isSignInOpen, setSignInOpen] = useState(false);
  const [isForgotOpen, setForgotOpen] = useState(false);
  const [isNewPasswordOpen, setNewPasswordOpen] = useState(false);
  const [isContactUsOpen, setContactUsOpen] = useState(false);
  const [isVideoOpen, setVideoOpen] = useState(false);
  const [isSuccessOpen, setSuccessOpen] = useState(false);
  const [isFailedOpen, setFailedOpen] = useState(false);
  const [isPreloaderOpen, setPreloaderOpen] = useState(false);
  const [companyType, setCompanyType] = useState<number | null>(null);

  function openSignUp(): void {
    setSignUpOpen(true);
    setSignInOpen(false);
  }
  function closeSignUp(): void {
    setSignUpOpen(false);
  }

  function openSignIn(): void {
    setSignInOpen(true);
    setSignUpOpen(false);
  }
  function closeSignIn(): void {
    setSignInOpen(false);
  }

  function openForgot(): void {
    setSignInOpen(false);
    setForgotOpen(true);
  }
  function closeForgot(): void {
    setForgotOpen(false);
  }

  function openNewPassword(): void {
    setNewPasswordOpen(true);
  }
  function closeNewPassword(): void {
    setNewPasswordOpen(false);
  }

  function openContactUs(companyType?: number): void {
    setContactUsOpen(true);
    if (companyType && typeof companyType == 'number') setCompanyType(companyType);
    else setCompanyType(null);
  }
  function closeContactUs(): void {
    setContactUsOpen(false);
  }

  function setType(type: number): void {
    setCompanyType(type);
  }

  function openVideo(): void {
    setVideoOpen(true);
  }
  function closeVideo(): void {
    setVideoOpen(false);
  }

  function openSuccess(): void {
    setSuccessOpen(true);
  }
  function closeSuccess(): void {
    setSuccessOpen(false);
  }

  function openFailed(): void {
    setFailedOpen(true);
  }
  function closeFailed(): void {
    setFailedOpen(false);
  }

  function openPreloader(): void {
    setPreloaderOpen(true);
  }
  function closePreloader(): void {
    setPreloaderOpen(false);
  }

  const store: ContextProps = {
    signUp: { isOpen: isSignUpOpen, open: openSignUp, close: closeSignUp },
    signIn: { isOpen: isSignInOpen, open: openSignIn, close: closeSignIn },
    forgot: { isOpen: isForgotOpen, open: openForgot, close: closeForgot },
    newPassword: { isOpen: isNewPasswordOpen, open: openNewPassword, close: closeNewPassword },
    contactUs: { isOpen: isContactUsOpen, open: openContactUs, close: closeContactUs },
    video: { isOpen: isVideoOpen, open: openVideo, close: closeVideo },
    success: { isOpen: isSuccessOpen, open: openSuccess, close: closeSuccess },
    failed: { isOpen: isFailedOpen, open: openFailed, close: closeFailed },
    preloader: { isOpen: isPreloaderOpen, open: openPreloader, close: closePreloader },
    companyType: { type: companyType, setType },
  };

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
}

export default Store;
