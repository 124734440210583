import React from 'react';

import CloseButton from '../Buttons/CloseButton';
import SubmitButton from '../Buttons/SubmitButton';
import { StoreContext } from '../../store';

import Modal from './Modal';
import style from './FailedPopup.module.css';

function FailedPopup(): JSX.Element {
  const { failed } = React.useContext(StoreContext);
  return (
    <Modal contact className={style.modal}>
      <CloseButton close={failed.close} />
      <h2 className={style.title}>Произошла ошибка!</h2>
      <SubmitButton title="Отправить еще раз" className={style.button} onClick={failed.close} />
    </Modal>
  );
}

export default FailedPopup;
