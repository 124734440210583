import React from 'react';

import { InfoTechnology, InfoGeology, InfoControl, InfoVisual, InfoExport, InfoRelation } from '../SVG';

import InfoHeader from './InfoHeader';
import InfoMock from './InfoMock.json';
import InfoItem from './InfoItem';
import style from './Info.module.css';

type Item = {
  id: number;
  text: string;
};

function Info(): JSX.Element {
  const infos = [
    { key: 0, className: style.blue, image: <InfoTechnology /> },
    { key: 1, className: style.gray, image: <InfoGeology /> },
    { key: 2, className: style.white, image: <InfoControl /> },
    { key: 3, className: style.blue, image: <InfoVisual /> },
    { key: 4, className: style.blue, image: <InfoExport /> },
    { key: 5, className: style.gray, image: <InfoRelation /> },
  ];
  return (
    <>
      <InfoHeader {...InfoMock.header} />
      <div className={style.container}>
        {InfoMock.items.map((item, index) => (
          <InfoItem key={item.id} {...item} className={infos[index].className}>
            {infos[index].image}
          </InfoItem>
        ))}
      </div>
    </>
  );
}

export default Info;
