import React from 'react';
import cn from 'classnames';

import CustomerFocus from '../../images/AdvantagesCustomerFocus.svg';
import Usability from '../../images/AdvantagesUsability.svg';
import Functionality from '../../images/AdvantagesFunctionality.svg';
import Reliability from '../../images/AdvantagesReliability.svg';

import style from './AdvantagesBody.module.css';
import { AdvantageItem, AdvantageGroup } from './types';

type Props = {
  advantage: AdvantageGroup;
  active: number;
  className?: string;
};

function AdvantagesBody({ advantage, active, className }: Props): JSX.Element {
  const images = [Usability, Reliability, Functionality, CustomerFocus];
  return (
    <div className={cn(style.container, className)}>
      <img src={images[active]} alt={advantage.title} className={style.logo} />
      {advantage.items.map((item: AdvantageItem) => (
        <div key={item.id} className={style.item}>
          <h3 className={style.title}>{item.title}</h3>
          <p className={style.description}>{item.description}</p>
        </div>
      ))}
    </div>
  );
}

export default AdvantagesBody;
