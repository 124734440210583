import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import PopupOpener from './components/Modals/PopupOpener';
import AlertCookie from './components/Alert/AlertCookie';
import DynamicScriptLoader from './components/Alert/DynamicScriptLoader';
import StoreProvider from './store';
import HeaderBy from './components/Header/HeaderBy';
import FooterBy from './components/Footer/FooterBy';
import HomePage from './pages/HomePage';

function App(): JSX.Element {
  const hostname = window.location.hostname;
  const isBY = hostname.endsWith('.by');
  return (
    <StoreProvider>
      <Router>
        <PopupOpener />
        {isBY ? <HeaderBy /> : <Header />}
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          {/* <Route path="/licence">
          <LicencePage />
        </Route> */}
        </Switch>
        {isBY ? <FooterBy /> : <Footer />}
        <AlertCookie />
        <DynamicScriptLoader />
      </Router>
    </StoreProvider>
  );
}

export default App;
