import React from 'react';
import { Link } from 'react-scroll';
import cn from 'classnames';

import style from './HeaderItem.module.css';

type Props = {
  children: React.ReactNode;
  to?: string;
  className?: string | false;
  onClick?: () => void;
};

function HeaderItem({ children, to, className, onClick }: Props): JSX.Element {
  return (
    <li className={cn(style.item, className)}>
      <Link to={to} activeClass={style.active} smooth duration={500} spy onClick={onClick}>
        {children}
      </Link>
    </li>
  );
}

export default HeaderItem;
