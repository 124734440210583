import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';

import InfoDrillbit from '../../images/InfoDrillbit.png';

type Props = {
  children: React.ReactNode;
};

function InfoControl(): JSX.Element {
  const [isDisplay, setDisplay] = useState(false);

  function display(): void {
    setDisplay(true);
  }

  return (
    <Waypoint onEnter={display} bottomOffset={300}>
      <svg viewBox="0 0 741 442" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="maskInfoControl" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="741" height="442">
          <rect width="741" height="442" fill="red" />
        </mask>

        {isDisplay && (
          <g mask="url(#maskInfoControl)">
            <svg
              width="741"
              x="0"
              y="442"
              height="71"
              viewBox="0 0 741 71"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <animate attributeName="y" from="442" to="370" dur="500ms" fill="freeze" />
              <rect width="741" height="71" fill="#F9F9F9" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" x="82" y="442" width="540" height="418" fill="none">
              <animate attributeName="y" from="442" to="107" dur="500ms" begin="500ms" fill="freeze" />
              <path
                fill="#C3EBC4"
                d="M474.709 109.955C450.962 100.875 393.5 334 393.5 334H463s35.457-214.964 11.709-224.045z"
              />
              <path
                fill="#C3EBC4"
                d="M497.802 149.476C466.638 143.072 447.349 334 447.349 334H493.5s35.465-178.12 4.302-184.524zM439.858 83.4068C395.951 52.6222 355.5 334 355.5 334h57s71.266-219.809 27.358-250.5932zM71.9125 169.391C39.8261 175.594 47.5001 334 47.5001 334H95.5s8.499-170.811-23.5875-164.609z"
              />
              <path
                fill="#C3EBC4"
                d="M180.5 334s-13.191-209.201-51.412-210.134c-19.385-.474-34.2791 33.35-36.6987 65.343C90.0382 220.297 92.5 334 92.5 334h88z"
              />
              <path fill="#C3EBC4" d="M174.5 103c-35.486.916-13 231-13 231H220s-10.014-231.916-45.5-231z" />
              <path
                fill="#C3EBC4"
                d="M229.669 85.3964C165.175 100.62 209.666 266.02 209.666 266.02l71.635.661s12.861-196.5083-51.632-181.2846z"
              />
              <path
                fill="#C3EBC4"
                d="M289.794 56.6126c-35.486.9158-41.257 208.7884-41.257 208.7884l73.638 1.271s3.104-210.9751-32.381-210.0594z"
              />
              <path
                fill="#C3EBC4"
                d="M348.464 24.9817c-45.414 14.1281-40.385 86.2653-40.385 86.2653L300.841 321.5l73.5 2s36.181-149.729 33.995-176.356c-2.007-24.439-8.339-56.5301-15.506-80.216-9.17-30.3059-27.118-47.3119-44.366-41.9463z"
              />
              <path
                stroke="#3CB276"
                strokeLinecap="round"
                strokeWidth="3"
                d="M463.5 327.5l12.168-66.042m19.733-83.585l-3.637 12.457m-3.637 12.457l-3.799-12.526m3.799 12.526l3.637-12.457m-3.637 12.457l-3.115 14.668-3.115 14.667m9.867-41.792l10.062-3.452m-26.158 74.58l-8.826-10.761m8.826 10.761l6.229-29.336m0 0l11.419-5.799M429 327.5l3-15m18.634-78.627l-3.637 12.457m-3.637 12.457l-3.799-12.526m3.799 12.526l3.637-12.457m-3.637 12.457l-3.115 14.668-3.115 14.667m9.867-41.792l10.062-3.452M432 312.5l-4-6m4 6l5.13-24.378m0 0l11.419-5.799M81.0547 327.5l-6.048-56.646m-2.4815-85.847l-.3027 12.974m-.3026 12.973l-6.9001-11.124m6.9001 11.124l.3026-12.973m-.3026 12.973l.7717 14.975.7717 14.975m-1.2408-42.923l8.8321-5.93m-6.0479 78.803l-11.3015-8.122m11.3015 8.122l-1.5434-29.95m0 0l9.538-8.547M296.469 266.465l-6.455-87.144m-1.059-97.5103l.314 28.8923m0 0l7.858-8.645m-7.858 8.645l.372 34.309m.373 34.309l-10.865-11.11m10.865 11.11l-.373-34.309m0 0l-9.835-6.646M138.185 323s-8.437-86.293-9.179-101.247m-1.089-78.534s-.825 10.68-.723 28.774m0 0l-10.871-13.409m10.871 13.409c.031 5.487.147 11.656.397 18.416m0 0c.036.975.075 1.961.117 2.96.449 10.732.901 20.375 1.298 28.384m-1.415-31.344l10.594-15.172m-9.179 46.516l-15.004-23.945"
              />
            </svg>
            <svg
              id="example3"
              x="350"
              y="442"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <animate attributeName="y" from="442" to="162" dur="500ms" begin="1000ms" fill="freeze" />
              <image width="215" height="295" xlinkHref={InfoDrillbit} />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" x="174" y="442" width="187" height="407" fill="none">
              <animate attributeName="y" from="442" to="29" dur="500ms" begin="1000ms" fill="freeze" />
              <path
                stroke="#2C2C2C"
                strokeWidth="4.5"
                d="M70.1598 33.5074L42.6377 73.3267l45.6568 22.5917-35.9324 40.3506 58.5229 28.013-43.3103 61.727 75.4863 39.977-57.2579 74.65 97.3809 41.201"
              />
              <path
                stroke="#2C2C2C"
                strokeWidth="4.5"
                d="M33.1408 27.9096L93.0402 403.677l90.1438-21.84L65.8773 19.9782l-32.7365 7.9314zM46.2828 11.6662L138.471 392.168M35.5251 41.8986l34.6343-8.3912M40.265 73.9013l39.8531-9.6557M45.595 106.263l44.5975-10.8047M50.5793 137.202l49.3419-11.954M56.799 177.386l55.51-13.449M64.7279 226.698l63.1011-15.288M73.9072 283.243l71.6408-17.358M82.9567 341.325l80.6553-19.541"
              />
              <path
                stroke="#2C2C2C"
                strokeWidth="4.5"
                d="M35.5249 41.8985l42.6953 22.8069L47.492 105.804l50.5308 19.904-39.3267 51.218 67.7089 34.829-50.1264 70.913 85.4344 39.576-68.6733 81.433"
              />
            </svg>
            <svg
              width="187"
              height="252"
              x="262"
              y="442"
              viewBox="0 0 187 252"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <animate attributeName="y" from="442" to="191" dur="500ms" fill="freeze" />
              <path
                d="M40.4534 68.7211C37.2776 56.4042 36.2798 43.6269 37.5049 30.9663C37.5839 28.2888 38.3743 25.6807 39.7951 23.4099C41.3859 21.2567 44.4716 19.9951 46.8167 21.2842C47.2906 18.5068 48.3301 15.8565 49.8706 13.4974C51.411 11.1384 53.4197 9.12091 55.772 7.57013C60.4964 4.4994 65.8055 2.4402 71.3649 1.52223C86.8428 -1.52415 102.874 0.0203047 117.485 5.96559C120.719 7.14165 123.708 8.90362 126.303 11.1632C138.358 22.5047 138.577 45.6815 134.628 60.5338C133.873 63.2766 132.845 66.184 133.256 69.0639"
                fill="#313C6F"
              />
              <path
                d="M19 174L1.18957 236.896C0.778327 238.447 0.73034 240.073 1.04933 241.645C1.36831 243.218 2.04569 244.696 3.0288 245.964C4.01191 247.233 5.27427 248.257 6.71775 248.958C8.16124 249.66 9.74697 250.018 11.3517 250.007L43.854 249.746"
                fill="#FFF67F"
              />
              <path
                d="M154.5 164.5L185.945 236.896C186.359 238.448 186.409 240.074 186.092 241.648C185.774 243.222 185.097 244.701 184.113 245.97C183.129 247.239 181.866 248.264 180.421 248.964C178.976 249.664 177.389 250.021 175.783 250.007L143.294 249.746"
                fill="#FFF67F"
              />
              <path
                d="M109.737 120.944H66.2635C68.6683 124.497 71.9071 127.406 75.6965 129.416C79.4859 131.427 83.7104 132.479 88.0003 132.479C92.2902 132.479 96.5147 131.427 100.304 129.416C104.093 127.406 107.332 124.497 109.737 120.944Z"
                fill="#4A9CCE"
              />
              <path
                d="M60.339 120.944H116.676C124.453 120.944 131.91 124.033 137.409 129.532C142.908 135.03 149 146.224 149 154L145.997 174.772H30.9772L27.4999 154C27.4999 150.146 31.7369 142.594 33.213 139.034C34.6891 135.474 36.8525 132.24 39.5795 129.517C42.3066 126.794 45.5437 124.635 49.1058 123.164C52.6679 121.693 56.4851 120.938 60.339 120.944Z"
                fill="#FFF67F"
              />
              <path
                d="M100.095 120.944V100.743H76.9185V120.944H72.9003C74.8937 123.091 77.3086 124.803 79.994 125.974C82.6793 127.145 85.5773 127.749 88.5069 127.749C91.4364 127.749 94.3344 127.145 97.0198 125.974C99.7051 124.803 102.12 123.091 104.113 120.944H100.095Z"
                fill="#FA92AE"
              />
              <path
                d="M88.5 124L81.78 122.996L80.8252 126.268L82.5 135.5H93L95.078 126.268L93.5991 122.367L88.5 124Z"
                fill="#313C6F"
              />
              <path d="M84 135.5L76.5 175H88.5H98.5L93 135.5H84Z" fill="#313C6F" />
              <path
                d="M91.6749 8.39291H85.3391C61.6823 8.39291 43.5387 30.1983 46.9397 54.4858L50.6562 80.5425C51.4741 87.1425 54.6539 93.2231 59.6078 97.6601C64.5616 102.097 70.9545 104.591 77.6043 104.679H99.4645C106.096 104.582 112.468 102.091 117.408 97.6652C122.348 93.2397 125.521 87.1781 126.344 80.5974L130.061 54.5407C133.489 30.1982 115.345 8.39291 91.6749 8.39291Z"
                fill="#FA92AE"
              />
              <path d="M88.5 124L81.0068 145.044L68 129.906L74.4976 114L88.5 124Z" fill="white" />
              <path d="M88.5 124L95.7131 145.044L108.72 129.906L102.211 114L88.5 124Z" fill="white" />
              <path
                d="M58.5408 82.1698C61.6227 76.7864 58.3715 69.1308 51.279 65.0705C44.1866 61.0103 35.9387 62.083 32.8568 67.4664C29.775 72.8498 33.0262 80.5054 40.1186 84.5656C47.2111 88.6259 55.459 87.5532 58.5408 82.1698Z"
                fill="#FA92AE"
              />
              <path
                d="M136.157 84.551C143.249 80.4908 146.5 72.8352 143.419 67.4518C140.337 62.0684 132.089 60.9957 124.996 65.0559C117.904 69.1162 114.653 76.7718 117.735 82.1552C120.816 87.5386 129.064 88.6113 136.157 84.551Z"
                fill="#FA92AE"
              />
              <path
                d="M107.642 152H27.3686C25.9725 152 24.591 152.284 23.3082 152.836C22.0255 153.387 20.8685 154.194 19.9077 155.208C18.947 156.222 18.2026 157.421 17.72 158.732C17.2374 160.043 17.0266 161.438 17.1006 162.833L21.4605 241.27C21.6031 243.898 22.7472 246.372 24.6572 248.182C26.5673 249.992 29.0979 251.001 31.7284 251H145.394C148.024 251.001 150.553 249.992 152.461 248.181C154.369 246.371 155.51 243.897 155.65 241.27L160.072 162.833C160.146 161.438 159.935 160.043 159.452 158.732C158.97 157.421 158.225 156.222 157.265 155.208C156.304 154.194 155.147 153.387 153.864 152.836C152.581 152.284 151.2 152 149.804 152H107.642Z"
                fill="#F3F3F3"
              />
              <g style={{ mixBlendMode: 'multiply' }} opacity="0.2">
                <path
                  d="M126.832 212.81C110.324 208.447 93.3703 206.142 76.8624 201.569C67.1832 198.88 57.6031 195.373 49.4235 189.523C37.8356 181.216 29.769 168.88 26.8057 154.933C26.6198 154.016 26.4587 153.099 26.3099 152.182C23.6681 152.431 21.225 153.692 19.4924 155.702C17.7598 157.712 16.872 160.314 17.0149 162.964L21.3774 241.389C21.5201 244.017 22.6648 246.491 24.576 248.3C26.4872 250.11 29.0194 251.118 31.6515 251.118H145.385C148.016 251.118 150.547 250.11 152.456 248.3C154.365 246.49 155.507 244.016 155.647 241.389L156.601 224.41C147.21 219.298 137.207 215.4 126.832 212.81Z"
                  fill="#E5E5E5"
                  fillOpacity="0.5"
                />
              </g>
              <path
                d="M154.861 243H22.3113C21.0824 243 20.0862 243.942 20.0862 245.103V248.897C20.0862 250.059 21.0824 251 22.3113 251H154.861C156.09 251 157.086 250.059 157.086 248.897V245.103C157.086 243.942 156.09 243 154.861 243Z"
                fill="#E5E5E5"
              />
              <g style={{ mixBlendMode: 'multiply' }}>
                <path d="M88.2195 38.8104L80.087 72.2041H96.9416L88.2195 38.8104Z" fill="#FA92AE" />
              </g>
              <path
                d="M75.8084 63.386C75.8084 65.6762 74.7524 67.5002 73.4359 67.5002C72.1193 67.5002 71.0634 65.6488 71.0634 63.386C71.0634 61.1232 72.133 59.2718 73.4359 59.2718C74.7387 59.2718 75.8084 61.1095 75.8084 63.386Z"
                fill="black"
                fillOpacity="0.7"
              />
              <path
                d="M105.952 63.386C105.952 65.6762 104.882 67.5002 103.579 67.5002C102.276 67.5002 101.207 65.6488 101.207 63.386C101.207 61.1232 102.263 59.2718 103.579 59.2718C104.896 59.2718 105.952 61.1095 105.952 63.386Z"
                fill="black"
                fillOpacity="0.7"
              />
              <path
                d="M88.5764 82.4076C86.8719 82.4231 85.212 81.8628 83.8655 80.8174C82.519 79.7721 81.5647 78.3029 81.1571 76.6477C81.1157 76.4847 81.1068 76.3152 81.1309 76.1487C81.155 75.9823 81.2117 75.8222 81.2976 75.6777C81.3836 75.5332 81.4972 75.407 81.6319 75.3063C81.7666 75.2057 81.9199 75.1326 82.0829 75.0912C82.2458 75.0497 82.4154 75.0408 82.5818 75.0649C82.7482 75.089 82.9083 75.1457 83.0528 75.2316C83.1974 75.3176 83.3236 75.4312 83.4242 75.5659C83.5249 75.7006 83.598 75.8539 83.6394 76.0169C83.9239 77.1097 84.5629 78.0772 85.4564 78.7679C86.3498 79.4585 87.4472 79.8332 88.5764 79.8332C89.7057 79.8332 90.8031 79.4585 91.6965 78.7679C92.59 78.0772 93.229 77.1097 93.5135 76.0169C93.5972 75.6877 93.8082 75.4052 94.1001 75.2316C94.392 75.058 94.7409 75.0075 95.07 75.0912C95.3992 75.1748 95.6816 75.3858 95.8552 75.6777C96.0288 75.9696 96.0794 76.3185 95.9957 76.6477C95.604 78.3114 94.654 79.7906 93.3039 80.8387C91.9538 81.8868 90.2853 82.4405 88.5764 82.4076Z"
                fill="black"
                fillOpacity="0.3"
              />
              <path
                d="M63.8908 46.8743C65.662 45.2644 67.8129 44.1305 70.142 43.5788C72.471 43.0271 74.902 43.0757 77.2072 43.72C77.6855 43.814 78.1816 43.7239 78.5963 43.4677C79.011 43.2115 79.3136 42.8082 79.4437 42.3384C79.5737 41.8686 79.5215 41.3671 79.2975 40.9341C79.0735 40.5012 78.6943 40.1688 78.2357 40.0035C75.274 39.1905 72.1545 39.1414 69.1687 39.8609C66.1828 40.5804 63.4281 42.0449 61.1618 44.1177C59.3241 45.7771 62.0532 48.4925 63.9046 46.8605L63.8908 46.8743Z"
                fill="black"
                fillOpacity="0.3"
              />
              <path
                d="M113.165 46.8744C111.394 45.2645 109.243 44.1306 106.914 43.5789C104.585 43.0272 102.154 43.0758 99.8488 43.7202C99.3729 43.8063 98.8821 43.711 98.473 43.4531C98.0639 43.1952 97.7664 42.7933 97.639 42.3268C97.5116 41.8603 97.5636 41.3629 97.7848 40.9329C98.006 40.5029 98.3804 40.1713 98.834 40.0037C101.794 39.1918 104.911 39.1433 107.894 39.8628C110.878 40.5823 113.63 42.0462 115.894 44.1179C117.732 45.7773 115.003 48.4927 113.151 46.8607L113.165 46.8744Z"
                fill="black"
                fillOpacity="0.3"
              />
              <g style={{ mixBlendMode: 'multiply' }} opacity="0.22">
                <g style={{ mixBlendMode: 'multiply' }} opacity="0.22">
                  <path
                    d="M45.5683 65.251C48.75 65.1687 51.5339 63.0841 53.7967 60.7939C57.338 57.1475 60.1079 52.8248 61.9411 48.084C63.7743 43.3431 64.6331 38.2814 64.4663 33.2012C69.4856 36.136 75.5883 36.4926 81.4031 36.4652C89.1241 36.4652 97.0234 35.7932 104.072 32.6527C111.121 29.5122 117.238 23.4231 118.65 15.8392C108.118 13.821 97.4896 12.3426 86.8064 11.4096C80.3608 10.8062 73.8467 10.2988 67.4559 11.3273C61.4629 12.2736 55.4698 14.7558 51.6436 19.4598C47.8174 24.1637 46.6243 30.431 46.2951 36.52C45.966 42.6091 46.3911 48.6981 45.198 54.6363"
                    fill="#FA92AE"
                  />
                </g>
              </g>
              <path
                d="M88.4941 2.50938C77.9755 2.1391 61.834 5.25219 54.2913 13.3023C48.2661 20.0256 44.0847 28.1947 42.1544 37.0139C40.0835 45.7772 39.4116 56.625 43.5258 64.9083C43.7124 65.3591 44.0271 65.7453 44.4309 66.0191C45.336 66.4991 46.3783 65.7586 47.1737 65.0729C51.7925 60.7378 55.2449 55.3092 57.2124 49.288C58.3803 45.6153 59.1437 41.8261 59.4889 37.9876C59.6124 36.8768 60.8603 26.3033 60.0924 26.0701C71.094 29.5524 82.6832 30.7883 94.1717 29.7043C99.1166 29.3851 103.965 28.187 108.489 26.1661C113.013 24.1066 116.651 20.4974 118.747 15.9903C111.177 7.58358 99.8357 2.93451 88.4941 2.50938Z"
                fill="#313C6F"
              />
              <path
                d="M119.007 47.3269H104.471C101.968 47.3335 99.5581 48.2732 97.7118 49.9623C95.8654 51.6514 94.7154 53.9685 94.4867 56.4604C88.8091 53.361 84.8183 54.9107 82.5281 56.4604C82.3058 53.9672 81.1601 51.6469 79.3156 49.9547C77.4712 48.2625 75.0611 47.3204 72.558 47.3131H58.0073C55.352 47.3204 52.8078 48.3794 50.9315 50.2582C49.0552 52.1371 47.9997 54.6828 47.9961 57.3381V65.8134C47.9997 68.4674 49.0557 71.0117 50.9323 72.8884C52.809 74.7651 55.3533 75.821 58.0073 75.8246H72.558C75.212 75.821 77.7563 74.7651 79.6329 72.8884C81.5096 71.0117 82.5656 68.4674 82.5692 65.8134V59.6969C84.1875 58.3255 87.8354 55.089 94.4456 59.3952V65.8271C94.4492 68.4835 95.507 71.0299 97.3867 72.907C99.2663 74.784 101.814 75.8384 104.471 75.8384H119.007C121.663 75.8384 124.209 74.7836 126.086 72.9061C127.964 71.0287 129.019 68.4823 129.019 65.8271V57.3655C129.02 56.049 128.763 54.7451 128.261 53.5281C127.759 52.3111 127.022 51.205 126.092 50.2728C125.162 49.3406 124.058 48.6007 122.843 48.0952C121.627 47.5897 120.324 47.3287 119.007 47.3269ZM79.3601 65.8271C79.3601 67.6457 78.6377 69.3898 77.3517 70.6758C76.0658 71.9617 74.3217 72.6841 72.5031 72.6841H58.0073C56.1887 72.6841 54.4446 71.9617 53.1587 70.6758C51.8728 69.3898 51.1503 67.6457 51.1503 65.8271V57.3655C51.1503 55.5469 51.8728 53.8028 53.1587 52.5169C54.4446 51.231 56.1887 50.5085 58.0073 50.5085H72.558C74.3766 50.5085 76.1206 51.231 77.4066 52.5169C78.6925 53.8028 79.415 55.5469 79.415 57.3655L79.3601 65.8271ZM125.81 65.8271C125.81 67.6457 125.087 69.3898 123.801 70.6758C122.515 71.9617 120.771 72.6841 118.953 72.6841H104.416C102.597 72.6841 100.853 71.9617 99.567 70.6758C98.2811 69.3898 97.5586 67.6457 97.5586 65.8271V57.3655C97.5586 55.5469 98.2811 53.8028 99.567 52.5169C100.853 51.231 102.597 50.5085 104.416 50.5085H118.953C120.771 50.5085 122.515 51.231 123.801 52.5169C125.087 53.8028 125.81 55.5469 125.81 57.3655V65.8271Z"
                fill="white"
              />
              <rect x="85.0836" y="211.423" width="4.83333" height="4.83333" rx="1" fill="#E5E5E5" />
              <rect x="97.1665" y="211.423" width="4.83333" height="4.83333" rx="1" fill="#E5E5E5" />
              <rect x="85.0836" y="199.712" width="4.83333" height="4.83333" rx="1" fill="#E5E5E5" />
              <rect x="97.1665" y="199.712" width="4.83333" height="4.83333" rx="1" fill="#E5E5E5" />
              <rect x="85.0836" y="188" width="4.83333" height="4.83333" rx="1" fill="#E5E5E5" />
              <rect x="73" y="211.423" width="4.83333" height="4.83333" rx="1" fill="#E5E5E5" />
              <rect x="73" y="199.712" width="4.83333" height="4.83333" rx="1" fill="#E5E5E5" />
              <rect x="73" y="188" width="4.83333" height="4.83333" rx="1" fill="#E5E5E5" />
              <rect x="97.1665" y="188" width="4.83333" height="4.83333" rx="1" fill="#E5E5E5" />
            </svg>
          </g>
        )}
      </svg>
    </Waypoint>
  );
}

export default InfoControl;
