import * as Yup from 'yup';

const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('This field is required'),
  password: Yup.string()
    .min(8, 'Must be longer than 8 characters')
    .max(16, 'Must be less than 16 characters')
    .required('This field is required'),
});

export default SignInSchema;
