import React from 'react';

type Props = {
  green?: boolean;
  white?: boolean;
};

function InputIconCard({ green, white }: Props): JSX.Element {
  function defineColor(): string {
    if (green) return 'var(--green)';
    else if (white) return '#FFFFFF';
    else return '#E5E5E5';
  }

  return (
    <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.7916 21.9998H3.2084C1.43854 21.9998 0 20.6435 0 18.9748V5.2248C0 3.55608 1.43854 2.19995 3.2084 2.19995H10.2084C10.6914 2.19995 11.0834 2.56955 11.0834 3.02495C11.0834 3.48035 10.6914 3.84994 10.2084 3.84994H3.2084C2.40454 3.84994 1.75 4.46688 1.75 5.2248V18.9748C1.75 19.7327 2.40454 20.3498 3.2084 20.3498H24.7916C25.5955 20.3498 26.25 19.7327 26.25 18.9748V5.2248C26.25 4.46688 25.5955 3.84994 24.7916 3.84994H17.7916C17.3086 3.84994 16.9166 3.48035 16.9166 3.02495C16.9166 2.56955 17.3086 2.19995 17.7916 2.19995H24.7916C26.5615 2.19995 28 3.55608 28 5.2248V18.9748C28 20.6435 26.5615 21.9998 24.7916 21.9998Z"
        fill={defineColor()}
      />
      <path
        d="M16.6275 6.0499H11.3775C10.2517 6.0499 9.33594 5.18643 9.33594 4.12498V1.92492C9.33594 0.863465 10.2517 0 11.3775 0H16.6275C17.7533 0 18.6691 0.863465 18.6691 1.92492V4.12498C18.6691 5.18643 17.7533 6.0499 16.6275 6.0499ZM11.3775 1.64999C11.2165 1.64999 11.0859 1.77326 11.0859 1.92492V4.12498C11.0859 4.27684 11.2165 4.39991 11.3775 4.39991H16.6275C16.7886 4.39991 16.9191 4.27684 16.9191 4.12498V1.92492C16.9191 1.77326 16.7886 1.64999 16.6275 1.64999H11.3775Z"
        fill={defineColor()}
      />
      <path
        d="M23.6275 9.89999H17.2109C16.7279 9.89999 16.3359 9.53039 16.3359 9.075C16.3359 8.6196 16.7279 8.25 17.2109 8.25H23.6275C24.1105 8.25 24.5025 8.6196 24.5025 9.075C24.5025 9.53039 24.1105 9.89999 23.6275 9.89999Z"
        fill={defineColor()}
      />
      <path
        d="M23.6275 14.2999H17.2109C16.7279 14.2999 16.3359 13.9303 16.3359 13.4749C16.3359 13.0195 16.7279 12.6499 17.2109 12.6499H23.6275C24.1105 12.6499 24.5025 13.0195 24.5025 13.4749C24.5025 13.9303 24.1105 14.2999 23.6275 14.2999Z"
        fill={defineColor()}
      />
      <circle cx="9.50078" cy="10.7" r="2" stroke={defineColor()} strokeWidth="1.4" strokeLinecap="round" />
      <path
        d="M14 18C14 15.7632 11.9853 12.95 9.5 12.95C7.01472 12.95 5 15.7632 5 18"
        stroke={defineColor()}
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M23.6275 18.7H17.2109C16.7279 18.7 16.3359 18.3304 16.3359 17.875C16.3359 17.4196 16.7279 17.05 17.2109 17.05H23.6275C24.1105 17.05 24.5025 17.4196 24.5025 17.875C24.5025 18.3304 24.1105 18.7 23.6275 18.7Z"
        fill={defineColor()}
      />
    </svg>
  );
}

export default InputIconCard;
