import React, { useState } from 'react';
import { FieldProps } from 'formik';

import Input from './Input';

type Props = {
  field: FieldProps['field'];
  form: FieldProps['form'];
  className?: string;
  placeholder?: string;
  props: object;
};

const PasswordInput = ({ field, form, className, placeholder, ...props }: Props): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const showPassword = (): void => {
    setOpen(!isOpen);
  };

  return (
    <Input
      field={field}
      {...form}
      form={form}
      className={className}
      placeholder={placeholder}
      icon={isOpen ? 'openEye' : 'eye'}
      showPassword={showPassword}
      {...props}
      type={isOpen ? 'text' : 'password'}
    />
  );
};

export default PasswordInput;
