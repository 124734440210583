import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';

function FooterAnimation(): JSX.Element {
  const [isDisplay, setDisplay] = useState(false);

  function display(): void {
    setDisplay(true);
  }

  return (
    <Waypoint onEnter={display} bottomOffset={300}>
      <svg viewBox="0 0 417 364" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask
          id="maskFooterAnimation1"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="417"
          height="349"
        >
          <rect width="417" height="349" fill="#CCFFCC" />
        </mask>
        <mask
          id="maskFooterAnimation2"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="417"
          height="349"
        >
          <rect width="417" height="349" fill="#CCFFCC" />
        </mask>

        {isDisplay && (
          <g mask="url(#maskFooterAnimation1)">
            <svg xmlns="http://www.w3.org/2000/svg" width="459" height="336" fill="none" y="364" x="-26">
              <animate attributeName="y" from="364" to="147" dur="500ms" begin="0" fill="freeze" />
              <path
                fill="#A9E3AB"
                d="M414.213 99.5943c-18.23-8.1913-71.143 118.6037-85.12 153.0447-1.965 4.841.216 10.264 5.048 12.254 5.793 2.385 13.738 5.323 21.148 6.938 7.954 1.735 17.22 2.892 23.678 3.564 4.693.489 8.97-2.463 10.236-7.009 9.183-32.989 43.353-160.55 25.01-168.7917z"
              />
              <path
                fill="#A9E3AB"
                d="M433.122 136.658c-24.901-6.618-60.331 104.536-67.767 128.873-.911 2.983-.276 6.144 2.25 7.975 3.253 2.358 8.923 5.415 17.957 7.218 9.023 1.8 17.696 2.846 23.624 3.419 4.542.44 8.653-2.357 9.951-6.732 8.61-29.016 38.069-134.353 13.985-140.753zM383.961 73.6935C350.263 47.0753 282.279 207.826 266.572 246.472c-1.915 4.712.128 9.96 4.855 11.836 8.185 3.248 21.849 7.692 37.785 8.954 17.384 1.377 33.061 1.59 41.535 1.58 4.021-.004 7.545-2.417 8.952-6.183 11.256-30.118 58.102-162.235 24.262-188.9655zM45.5788 132.65c-25.0445 3.373-18.6816 107.808-16.2226 138.924.3947 4.994 4.4834 8.769 9.4796 8.403 5.662-.416 13.4451-1.418 21.413-3.786 8.9658-2.664 16.7703-6.12 21.7333-8.547 3.5079-1.715 5.4437-5.41 5.0191-9.291-2.9284-26.771-15.8221-129.151-41.4224-125.703z"
              />
              <path
                fill="#A9E3AB"
                d="M142.929 252.294c-2.044-29.568-12.534-155.5-43.1016-157.9962-17.5677-1.4346-32.8387 28.4892-36.6935 57.3982-3.1311 23.483 6.411 89.425 9.6264 110.431.608 3.972 3.5776 7.073 7.5855 7.36 5.7173.409 15.1957.453 28.5442-1.512 13.828-2.035 23.269-4.95 28.72-7.016 3.574-1.355 5.582-4.852 5.319-8.665z"
              />
              <path
                fill="#A9E3AB"
                d="M147.479 74.4985c-27.941-.874-32.93 141.0685-33.765 179.0315-.115 5.25 3.904 9.529 9.154 9.614 7.411.12 18.48-.071 30.353-1.571 12.558-1.588 24.263-3.955 31.682-5.612 4.714-1.053 7.849-5.375 7.427-10.186-3.087-35.134-16.789-170.3986-44.851-171.2765z"
              />
              <path
                fill="#A9E3AB"
                d="M193.103 64.6005c-52.181 9.2118-43.307 148.4385-40.416 181.8145.373 4.301 3.534 7.744 7.799 8.415 7.556 1.188 20.347 2.788 34.542 2.812 13.561.023 26.298-.516 34.322-.95 4.953-.268 8.864-4.196 9.116-9.15 1.956-38.451 6.562-192.108-45.363-182.9415z"
              />
              <path
                fill="#A9E3AB"
                d="M249.161 41.5956c-28.35-.8869-46.57 162.9794-50.564 202.6414-.498 4.945 2.772 9.369 7.678 10.169 6.332 1.033 16.102 2.348 28.982 3.262 12.705.901 22.746 1.213 29.429 1.305 5.276.073 9.51-4.112 9.633-9.387.966-41.551 3.046-207.1081-25.158-207.9904z"
              />
              <path
                fill="#A9E3AB"
                d="M304.048 15.9315c-41.947 10.467-41.124 76.1945-41.124 76.1945l-22.49 155.575c-.689 4.763 2.141 9.252 6.847 10.258 6.925 1.479 18.509 3.43 35.458 4.677 17.745 1.304 30.197 1.679 37.478 1.765 4.557.054 8.421-3.113 9.397-7.565 5.537-25.269 22.943-106.508 22.436-126.933-.554-22.284-4.637-51.7359-9.913-73.6033-6.751-27.9791-22.158-44.3434-38.089-40.3682z"
              />
              <path
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="2.5"
                d="M395.049 267.625l13.355-41.092m22.242-74.833l-3.946 11.117m-3.947 11.116l-2.798-11.565m2.798 11.565l3.947-11.116m-3.947 11.116l-7.175 26.3m11.122-37.416l9.31-2.611m-27.606 66.327l-7.452-10.224m7.452 10.224l7.174-26.3m0 0l10.664-4.671M199.562 246.478l-2.788-83.825m-2.076-78.0407l-.303 11.7925m-.302 11.7922l-6.249-10.1257m6.249 10.1257l.302-11.7922m-.302 11.7922l1.34 27.228m-1.038-39.0202l8.041-5.3712m-5.662 71.6194l-10.256-7.406m10.256 7.406l-1.341-27.228m0 0l8.689-7.749M370.396 260.327l8.623-33.448m30.916-110.04l-4.262 13.38m0 0l-4.678-13.777m4.678 13.777l-3.907 12.266m0 0l11.134-3.634m-11.134 3.634l-7.989 29.64m0 0l-7.784-15.48m7.784 15.48l-6.949 25.782m0 0l10.657-6.206m-10.657 6.206l-7.809 28.972m0 0l-5.669-17.641M377.053 88.0283c-1.425 8.474-2.489 10.6069-3.659 20.2747m-30.539 145.274s9.543-41.614 16.889-74.34m13.65-70.934c-.158 1.3-.317 2.736-.479 4.342-.462 4.567-.963 8.836-1.426 12.488m1.905-16.83L368.8 93.9516m2.689 31.1814l7.852-7.119m-7.852 7.119c-.251 1.988-2.442 12.222-5.479 25.99m0 0l9.069-7.037m-9.069 7.037c-1.855 8.411-4.027 18.14-6.266 28.114m0 0l-6.639-13.702M241.135 246.581L243 152.97m4.095-88.5493l-1.213 26.2368m0 0l7.579-7.4377m-7.579 7.4377l-1.441 31.1565M243 152.97l-9.285-10.646M243 152.97l1.441-31.156m0 0l-8.582-6.542M148.572 95.3959l-.337 16.2431m0 0l-4.95-8.512m4.95 8.512l-.303 14.62m0 0l-.842 25.732 3.075 95.201m-2.233-120.933l8.248-13.53M100.119 254.676s-5.5432-57.937-5.441-71.547m3.0844-71.329s-1.3026 9.651-2.1483 26.077m0 0l-9.1703-12.733m9.1703 12.733c-.2565 4.981-.471 10.586-.5944 16.734m0 0c-.0178.886-.0337 1.783-.0476 2.692-.1488 9.763-.2393 18.538-.2941 25.826m.3417-28.518l10.4003-13.22m-10.742 41.738l-12.3747-22.508"
              />
            </svg>
          </g>
        )}
        {isDisplay && (
          <g mask="url(#maskFooterAnimation2)">
            <svg xmlns="http://www.w3.org/2000/svg" width="143" height="351" fill="none" x="202" y="364">
              <animate attributeName="y" from="364" to="0" dur="500ms" begin="500ms" fill="freeze" />
              <animate attributeName="opacity" from="1" to="0" dur="1ms" begin="1000ms" fill="freeze" />
              <path
                stroke="#59AFEC"
                strokeLinecap="round"
                strokeWidth="4"
                d="M125.748 6.64658s-12.758-2.77016-23.715 2.34524C91.0754 14.1072 88.3044 20.1218 88.3044 20.1218M88.8619 4.61331s-4.5337 3.02806-6.7696 7.73389c-2.2359 4.7059-1.9703 7.017-1.9703 7.017"
              />
              <path
                stroke="#2C2C2C"
                strokeWidth="4"
                d="M94.4541 50.2145L65.0732 76.4676l31.8136 26.5944-36.1372 25.067 40.9594 33.341-46.0572 40.574 52.0972 46.046-59.5606 48.136 69.1886 51.196"
              />
              <path
                stroke="#2C2C2C"
                strokeWidth="4"
                d="M66.2302 38.7159L41.8774 347.491l75.4996-.069L93.6484 38.6907l-27.4182.0252zM79.7312 28.3724l.2929 318.6866M65.4462 50.2408l29.0076-.0266M63.0859 76.4689l33.3787-.0307M61.1243 103.094l37.3523-.034M59.1605 128.527l41.3255-.038M56.4094 161.511l46.4916-.042M53.2678 202.045l52.8492-.048M49.7341 248.541l60.0019-.055M45.8042 296.228l67.5518-.062"
              />
              <path
                stroke="#2C2C2C"
                strokeWidth="4"
                d="M65.4461 50.241l29.4291 26.1991-32.1621 26.6529 36.1836 25.398-40.8982 33.019 46.9265 40.488-53.2044 46.541 60.0454 47.628-69.8886 51.325"
              />
              <path
                fill="#6977B4"
                d="M38.5553 319.531c-.004-4.418 3.5745-8.003 7.9928-8.007l88.1729-.079c4.418-.004 8.003 3.575 8.007 7.993l.027 30.518-104.1724.093-.0273-30.518z"
              />
              <path
                fill="#4455A1"
                fillOpacity=".2"
                d="M81.0072 311.457l53.7218-.048c4.418-.004 8.003 3.574 8.007 7.993l.028 30.517-61.7223.056-.0345-38.518z"
              />
              <path fill="#59AFEC" d="M2.63086 282.676l46.39628143-.041516.06031565 67.40587301-46.39628142.041516z" />
              <path
                fill="#000"
                fillOpacity=".1"
                d="M25.3914 282.655l23.63589054-.0211497.06031566 67.40587301-23.63589054.0211497z"
              />
              <rect
                width="35.2302"
                height="13.131"
                x="20.1649"
                y="311.549"
                fill="#313C6F"
                rx="6.56551"
                transform="rotate(-.051 20.1649 311.549)"
              />
              <mask id="a" fill="#fff">
                <rect
                  width="18.3834"
                  height="23.6359"
                  x="28.0199"
                  y="285.279"
                  rx="1"
                  transform="rotate(-.051 28.0199 285.279)"
                />
              </mask>
              <rect
                width="18.3834"
                height="23.6359"
                x="28.0199"
                y="285.279"
                fill="#BDDFF7"
                stroke="#313C6F"
                strokeWidth="5"
                mask="url(#a)"
                rx="1"
                transform="rotate(-.051 28.0199 285.279)"
              />
              <rect
                width="52.5241"
                height="5.25241"
                y="277.425"
                fill="#313C6F"
                rx="2.62621"
                transform="rotate(-.051 0 277.425)"
              />
            </svg>
          </g>
        )}
        {isDisplay && (
          <svg xmlns="http://www.w3.org/2000/svg" width="143" height="351" fill="none" opacity="0" x="202" y="0">
            <animate attributeName="y" from="0" to="17" dur="250ms" begin="1000ms" fill="freeze" />
            <animate attributeName="opacity" from="1" to="0" dur="1ms" begin="0ms" fill="freeze" />
            <animate attributeName="opacity" from="0" to="1" dur="1ms" begin="1000ms" fill="freeze" />
            <path
              stroke="#59AFEC"
              strokeLinecap="round"
              strokeWidth="4"
              d="M125.748 6.64658s-12.758-2.77016-23.715 2.34524C91.0754 14.1072 88.3044 20.1218 88.3044 20.1218M88.8619 4.61331s-4.5337 3.02806-6.7696 7.73389c-2.2359 4.7059-1.9703 7.017-1.9703 7.017"
            />
            <path
              stroke="#2C2C2C"
              strokeWidth="4"
              d="M94.4541 50.2145L65.0732 76.4676l31.8136 26.5944-36.1372 25.067 40.9594 33.341-46.0572 40.574 52.0972 46.046-59.5606 48.136 69.1886 51.196"
            />
            <path
              stroke="#2C2C2C"
              strokeWidth="4"
              d="M66.2302 38.7159L41.8774 347.491l75.4996-.069L93.6484 38.6907l-27.4182.0252zM79.7312 28.3724l.2929 318.6866M65.4462 50.2408l29.0076-.0266M63.0859 76.4689l33.3787-.0307M61.1243 103.094l37.3523-.034M59.1605 128.527l41.3255-.038M56.4094 161.511l46.4916-.042M53.2678 202.045l52.8492-.048M49.7341 248.541l60.0019-.055M45.8042 296.228l67.5518-.062"
            />
            <path
              stroke="#2C2C2C"
              strokeWidth="4"
              d="M65.4461 50.241l29.4291 26.1991-32.1621 26.6529 36.1836 25.398-40.8982 33.019 46.9265 40.488-53.2044 46.541 60.0454 47.628-69.8886 51.325"
            />
            <path
              fill="#6977B4"
              d="M38.5553 319.531c-.004-4.418 3.5745-8.003 7.9928-8.007l88.1729-.079c4.418-.004 8.003 3.575 8.007 7.993l.027 30.518-104.1724.093-.0273-30.518z"
            />
            <path
              fill="#4455A1"
              fillOpacity=".2"
              d="M81.0072 311.457l53.7218-.048c4.418-.004 8.003 3.574 8.007 7.993l.028 30.517-61.7223.056-.0345-38.518z"
            />
            <path fill="#59AFEC" d="M2.63086 282.676l46.39628143-.041516.06031565 67.40587301-46.39628142.041516z" />
            <path
              fill="#000"
              fillOpacity=".1"
              d="M25.3914 282.655l23.63589054-.0211497.06031566 67.40587301-23.63589054.0211497z"
            />
            <rect
              width="35.2302"
              height="13.131"
              x="20.1649"
              y="311.549"
              fill="#313C6F"
              rx="6.56551"
              transform="rotate(-.051 20.1649 311.549)"
            />
            <mask id="a" fill="#fff">
              <rect
                width="18.3834"
                height="23.6359"
                x="28.0199"
                y="285.279"
                rx="1"
                transform="rotate(-.051 28.0199 285.279)"
              />
            </mask>
            <rect
              width="18.3834"
              height="23.6359"
              x="28.0199"
              y="285.279"
              fill="#BDDFF7"
              stroke="#313C6F"
              strokeWidth="5"
              mask="url(#a)"
              rx="1"
              transform="rotate(-.051 28.0199 285.279)"
            />
            <rect
              width="52.5241"
              height="5.25241"
              y="277.425"
              fill="#313C6F"
              rx="2.62621"
              transform="rotate(-.051 0 277.425)"
            />
          </svg>
        )}
      </svg>
    </Waypoint>
  );
}

export default FooterAnimation;
