import React from 'react';
import cn from 'classnames';

import tatneft from '../../images/ClientsLogo2.png';

import { Review } from './types';
import style from './ReviewItem.module.css';

type ReviewItem = {
  key?: number;
  review: Review;
  className: string;
};

function ReviewItem({ review, className }: ReviewItem): JSX.Element {
  return (
    <div className={cn(className, style.container)}>
      <img src={tatneft} alt={review.name} className={style.photo} />
      <p className={style.name}>{review.name}</p>
      <p className={style.company}>{review.company}</p>
      <p className={style.review}>{review.review}</p>
    </div>
  );
}

export default ReviewItem;
