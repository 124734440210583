import React from 'react';
import { Formik, Field, Form } from 'formik';

import CloseButton from '../Buttons/CloseButton';
import PasswordInput from '../Inputs/PasswordInput';
import SubmitButton from '../Buttons/SubmitButton';
import { StoreContext } from '../../store';

import Modal from './Modal';
import Schema from './CreateNewPasswordSchema';
import style from './CreateNewPassword.module.css';

type MyFormValues = {
  password: string;
  confirmPassword: string;
};

function CreateNewPassword(): JSX.Element {
  const { newPassword } = React.useContext(StoreContext);

  function handleSubmit(values: MyFormValues): void {
    if (values.password && values.password === values.confirmPassword) {
      newPassword.close();
    }
  }

  function renderForm(): JSX.Element {
    return (
      <Form className={style.form}>
        <Field type="password" name="password" component={PasswordInput} placeholder={'Новый пароль'} icon="eye" />
        <Field
          type="password"
          name="confirmPassword"
          component={PasswordInput}
          placeholder={'Повторите пароль'}
          icon="eye"
        />
        <SubmitButton title="Сохранить" className={style.button} />
      </Form>
    );
  }

  return (
    <Modal createPass>
      <CloseButton close={newPassword.close} />
      <h2 className={style.title}>Новый пароль</h2>
      <p className={style.description}>Создайте новый пароль для Вашего аккаунта</p>
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        validationSchema={Schema}
        onSubmit={handleSubmit}
        render={renderForm}
      />
    </Modal>
  );
}

export default CreateNewPassword;
