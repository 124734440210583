import React from 'react';
import { useCookies } from 'react-cookie';

import { ReactComponent as IconCookie } from '../../images/IconCookie.svg';
import CloseButton from '../Buttons/CloseButton';
import SubmitButton from '../Buttons/SubmitButton';

import style from './AlertCookie.module.css';
import { COOKIE_CONSENT_NO } from './constants';

function AlertCookie(): JSX.Element {
  const [cookies, setCookie] = useCookies(['cookieConsent']);

  function handleDisagree(): void {
    setCookie('cookieConsent', 'no');
  }
  function handleAgree(): void {
    setCookie('cookieConsent', 'needed');
    // setCookie('cookieConsent', "all");
  }

  if (cookies['cookieConsent'] || cookies['cookieConsent'] === COOKIE_CONSENT_NO) {
    return <></>;
  }
  return (
    <div className={style.container}>
      <div className={style.header}>
        <IconCookie />
        <p className={style.title}>Cookie-файлы на сайте</p>
        <CloseButton close={handleDisagree} className={style.closeBtn} />
      </div>
      <p className={style.text}>
        Наш сайт использует файлы cookie для хранения данных. Нажимая кнопку “Согласен”, Вы подтверждаете согласие со
        всеми пунктами <span className={style.link}> Пользовательского соглашения</span>.
      </p>
      <SubmitButton title="Согласен" onClick={handleAgree} className={style.btn} />
    </div>
  );
}

export default AlertCookie;
