import React from 'react';
import cn from 'classnames';

import style from './SubmitButton.module.css';

type Props = {
  title: string;
  className?: string;
  small?: boolean;
  onClick?: any;
};

function SubmitButton({ title, className, small, onClick }: Props): JSX.Element {
  return (
    <button className={cn(style.button, className, small && style.small)} type="submit" onClick={onClick}>
      {title}
    </button>
  );
}

export default SubmitButton;
